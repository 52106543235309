import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { isCompletedLogin, selectAuthError } from '../store/reducers/auth/types'
import { useLocation } from 'react-router'

interface IProps {
  children?: React.ReactNode
  styleName?: string
}

const PrivateLayout: React.FC<IProps> = (props) => {
  const { children } = props

  const location = useLocation()
  const loginCompleted = useSelector(isCompletedLogin)
  const error = useSelector(selectAuthError)

  return (
    <>
      {!error ? (
        loginCompleted ? (
          <>
            {children}
            <Outlet />
          </>
        ) : (
          <Navigate to="/login" replace state={{ from: location }} />
        )
      ) : (
        <Navigate to="/login" replace state={{ from: location }} />
      )}
    </>
  )
}
export default PrivateLayout
