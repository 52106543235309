import { StationState } from './types'
import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../store'
import { fetchStationById, fetchStations } from './thunk'

const initialState: StationState = {
  success: false,
  error: null,
  stations: null,
  station: null,
  loading: 'idle',
  currentRequestId: undefined,
}

const stationsSlice = createSlice({
  name: 'stationsReducer',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchStations.pending.type]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [fetchStations.fulfilled.type]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.stations = action.payload
        state.currentRequestId = undefined
      }
    },
    [fetchStations.rejected.type]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.error = action.error
        state.currentRequestId = undefined
      }
    },
    [fetchStationById.pending.type]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [fetchStationById.fulfilled.type]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.station = action.payload
        state.currentRequestId = undefined
      }
    },
    [fetchStationById.rejected.type]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.error = action.error
        state.currentRequestId = undefined
      }
    },
  },
})

export const selectStations = (state: RootState) => state.stationsReducer.stations
export const selectStationsLoading = (state: RootState) => state.stationsReducer.loading === 'pending'
export const selectStation = (state: RootState) => state.stationsReducer.station
export const selectStationLoading = (state: RootState) => state.stationsReducer.loading === 'pending'

export default stationsSlice.reducer
