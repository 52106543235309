import { NotificationState } from './types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: NotificationState = {
  type: null,
  messages: null,
}

const notificationsSlice = createSlice({
  name: 'notificationsReducer',
  initialState,
  reducers: {
    setNotification: (state, action: PayloadAction<NotificationState>) => {
      const { type, messages } = action.payload
      state.type = type
      state.messages = messages
    },
    clearNotification: (state) => {
      state.type = null
      state.messages = null
    },
  },
})

export const { setNotification, clearNotification } = notificationsSlice.actions

export default notificationsSlice.reducer
