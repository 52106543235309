import React from 'react'
import { TextField } from '@mui/material'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker'

export interface IProps {
  name: string
  label: string
  handleChange: (newValue: Date | null, name: string) => void
  format: string
}

const FormDateField: React.FC<IProps> = (props) => {
  const { name, label, format, handleChange } = props

  const [value, setValue] = React.useState<Date | null>(null)

  const handleChangeInLine = (newValue: Date | null) => {
    setValue(newValue)
    handleChange(newValue, name)
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        label={label}
        value={value}
        inputFormat={format}
        onChange={handleChangeInLine}
        renderInput={(params) => <TextField name={name} {...params} fullWidth margin="dense" />}
      />
    </LocalizationProvider>
  )
}

export default FormDateField
