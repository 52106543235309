export interface PartnerStaff {
  id: string
  firstName: string
  lastName: string
  pin: string
  avatar: string
  partnerId: string
}

export interface PartnerStaffState {
  success: boolean
  error: string | null
  partnerStaffs: PartnerStaff[] | null
  currentRequestId: string | undefined
  loading: 'idle' | 'pending'
}

export const PARTNER_STAFF_API_URL = '/partners/:partnerId/staff'
