export interface PartnerStaffStationSignOn {
  uuid: string
  staffId: string
  stationId: string
  partnerId: string
  version: string
  new: boolean
}

export interface PartnerStaffStationSignOnState {
  success: boolean
  error: string | null
  partnerStaffStationSignOn: PartnerStaffStationSignOn[] | null
  signOnSuccess: boolean | null
  signOffSuccess: boolean | null
  currentRequestId: string | undefined
  loading: 'idle' | 'pending'
}

export const PARTNER_STAFF_GET_SIGN_ON_API_URL = '/partners/:partnerId/stations/:stationId/staff'
export const PARTNER_STAFF_POST_SIGN_ON_API_URL = '/partners/:partnerId/stations/:stationId/staff/:staffId/sign-on'
export const PARTNER_STAFF_POST_SIGN_OFF_API_URL = '/partners/:partnerId/stations/:stationId/staff/:staffId/sign-off'
