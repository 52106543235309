import React from 'react'
import homeIcon from '../../assets/svg/header-icon-home.svg'
import { useNavigate } from 'react-router'
import { Box, Grid, Tab, Table, TableBody, TableCell, TableRow, Tabs, Typography } from '@mui/material'
import FormDateField from '../../components/form-filed/FormDateField'
import FormSelect from '../../components/form-filed/FormSelect'
import { reportTypeIdNames, sampleData, transationListColumns } from './types'
import Button from '@mui/material/Button'
import FormTextField from '../../components/form-filed/FormTextField'
import CustomTableHead from '../../components/table/CustomTableHead'
import usePagination from '../../hook/usePagination'
import { toDisplayHoursOrMinutes } from '../../util/util'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'

interface IProps {}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const ActivityList: React.FC<IProps> = (props) => {
  const navigate = useNavigate()

  const [sortDirection, sortBy, handleRequestSort] = usePagination()
  const [value, setValue] = React.useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }
  const handleBackClick = () => {
    navigate('/home')
  }

  const onChangeField = (event: any) => {}
  const handleChangeSelect = (event: any) => {}
  const handleChangeDate = (newValue: Date | null, name: string) => {}

  return (
    <>
      <div id="header-wrapper" className="landing-page-header">
        <div id="header">
          <div className="home-icon" onClick={() => navigate('/home')}>
            <img src={homeIcon} alt={'user-icon'} />
          </div>
          <div className="nav-icons">
            <ArrowBackIosIcon fontSize={'large'} style={{ marginRight: 20 }} onClick={() => handleBackClick()} />
          </div>
          <div className="nav-text activity-avatar">Activity</div>
          <div className="header-right">
            <div id="valido-logo" onClick={() => navigate('/home')}>
              <span>Valido</span>
            </div>
          </div>
        </div>
      </div>

      <div id="main-no-grid">
        <div className="col-header">
          <Box style={{ padding: 0 }}>
            <Tabs value={value} onChange={handleChange}>
              <Tab label="Reports" {...a11yProps(0)} style={{ padding: 0 }} />
              <Tab label="Activity" {...a11yProps(1)} />
            </Tabs>
          </Box>

          <TabPanel value={value} index={0}>
            <Grid container spacing={1} style={{ marginBottom: 16 }}>
              <Grid item xs={12} md={12}>
                <div className="text-heading-pink">Select Report</div>
              </Grid>
              <Grid item xs={3} md={3}>
                <FormDateField
                  name="startDate"
                  label="Start Date DD/MM/YY"
                  handleChange={handleChangeDate}
                  format={'dd/MM/yy'}
                />
              </Grid>

              <Grid item xs={3} md={3}>
                <FormDateField
                  name="endDate"
                  label="End Date DD/MM/YY"
                  handleChange={handleChangeDate}
                  format={'dd/MM/yyyy'}
                />
              </Grid>
              <Grid item xs={6} md={6} style={{ display: 'flex' }}>
                <FormSelect
                  label="Report Type"
                  name="reportType"
                  onChange={handleChangeSelect}
                  hideNone
                  data={reportTypeIdNames}
                />
                <Button
                  data-cy="Go"
                  id="Go"
                  size={'small'}
                  color={'primary'}
                  variant={'contained'}
                  style={{ height: 54, marginLeft: 8, marginTop: 7 }}
                >
                  Go
                </Button>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Grid container spacing={1} style={{ marginBottom: 16 }}>
              <Grid item xs={12} md={12}>
                <div className="text-heading-pink">Activity</div>
              </Grid>

              <Grid item xs={2} md={2}>
                <FormTextField name="code" label="Transaction" onChange={onChangeField} />
              </Grid>

              <Grid item xs={2} md={2}>
                <FormDateField name="date" label="Date" handleChange={handleChangeDate} format={'dd/MM/yyyy'} />
              </Grid>

              <Grid item xs={2} md={2}>
                <FormTextField name="station" label="Station" onChange={onChangeField} />
              </Grid>
              <Grid item xs={2} md={2}>
                <FormTextField name="staffId" label="Staff ID" onChange={onChangeField} />
              </Grid>

              <Grid item xs={2} md={2}>
                <FormTextField name="reward" label="Spend" onChange={onChangeField} />
              </Grid>

              <Grid item xs={2} md={2}>
                <FormTextField name="TimeReward" label="Credit" onChange={onChangeField} />
              </Grid>
            </Grid>

            <Table aria-label="tableTitle" size="medium">
              <CustomTableHead
                onRequestSort={handleRequestSort}
                sortDirection={sortDirection}
                sortBy={sortBy}
                columns={transationListColumns}
              />
              <TableBody>
                {sampleData && sampleData.length > 0 ? (
                  sampleData.map((row) => (
                    <TableRow key={row.id} data-id={row.id} tabIndex={0} role="button" hover>
                      <TableCell>{row.claimId}</TableCell>
                      <TableCell>{row.createdInstant}</TableCell>
                      <TableCell>{row.stationId}</TableCell>
                      <TableCell>{row.issuedByStaffId}</TableCell>
                      <TableCell>{row.campaignId}</TableCell>
                      <TableCell>{toDisplayHoursOrMinutes(row.timeValueMinutes)}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6}>No Record found</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TabPanel>
        </div>
      </div>
    </>
  )
}

export default ActivityList
