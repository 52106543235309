import * as React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Backdrop, CircularProgress } from '@mui/material'

const useStyle = makeStyles((theme) => ({
  backdrop: {
    zIndex: 10,
  },
  progressSpinner: {
    width: '50px !important',
    height: '50px !important',
    maxWidth: '50px',
    marginBottom: '30px',
  },
}))

interface IProps {}

const Loading: React.FC<IProps> = (props) => {
  const classes = useStyle()
  return (
    <>
      <Backdrop className={classes.backdrop} open>
        <CircularProgress className={classes.progressSpinner} color="primary" size={30} />
      </Backdrop>
    </>
  )
}

export default Loading
