import { Lifecycle } from '../../store/reducers/common/types'
import React, { useEffect, useState } from 'react'
import { Field, FieldProps } from 'react-final-form'
import makeStyles from '@mui/styles/makeStyles'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { Button, FormControl, Menu, MenuItem } from '@mui/material'

const useStyles = makeStyles(() => ({
  root: {
    width: '180px',
    textTransform: 'uppercase',
    color: '#FFFFFF !important',
    marginBottom: '18px',
    letterSpacing: '0.0px',
    '&.Mui-disabled': {
      opacity: 1,
      '-webkit-text-fill-color': '#FFFFFF',
    },
  },

  active: {
    fontWeight: 'bold',
    fontSize: '14 !important',
    width: '180px',
    border: 'none',
    textAlign: 'left',
    marginRight: '-1px',
    height: '50px',
    backgroundColor: '#42A545 !important',
    '&:disabled': {
      backgroundColor: '#42A545 !important',
      color: '#FFFFFF !important',
      '-webkit-text-fill-color': '#FFFFFF !important',
    },
    '&:hover': {
      backgroundColor: '#42A545 !important',
      border: 'none',
    },
  },

  draft: {
    fontWeight: 'bold',
    fontSize: '14 !important',
    width: '180px',
    border: 'none',
    textAlign: 'left',
    height: '50px',
    backgroundColor: '#1B8BD1 !important',
    marginRight: '-1px',
    '&:disabled': {
      backgroundColor: '#1B8BD1 !important',
      border: 'none',
    },
    '&:hover': {
      backgroundColor: '#1B8BD1 !important',
      border: 'none',
    },
  },

  suspended: {
    fontWeight: 'bold',
    fontSize: '14 !important',
    width: '180px',
    textAlign: 'left',
    border: 'none',
    height: '50px',
    backgroundColor: '#E3CB13 !important',
    marginRight: '-1px',
    '&:disabled': {
      backgroundColor: '#E3CB13 !important',
      border: 'none',
      '-webkit-text-fill-color': '#FFFFFF !important',
    },
    '&:hover': {
      backgroundColor: '#E3CB13 !important',
      border: 'none',
    },
  },
  activeIcon: {
    color: '#42A545',
    paddingRight: 5,
  },
  draftIcon: {
    color: '#1B8BD1',
    paddingRight: 5,
  },
  suspendedIcon: {
    color: '#E3CB13',
    paddingRight: 5,
  },
  iconColor: {
    color: '#FFFFFF !important',
  },
  textColor: {
    color: '#147AC6 !important',
  },
  dropdownStyle: {
    color: '#FFFFFF !important',
  },
  iconDropDown: {
    color: '#FFFFFF !important',
  },
}))

interface LifecycleSelectProps {
  name: string
  fieldProps?: Partial<FieldProps<any, any>>
  defaultValue?: Lifecycle
  onUpdateLifeCycle: (lifeCycle: Lifecycle) => void
  disabled: boolean
}

const LifecycleSelect: React.FC<LifecycleSelectProps> = (props) => {
  const classes = useStyles()

  const { name, fieldProps, defaultValue, onUpdateLifeCycle, disabled } = props

  const [className, setClassName] = useState<string>(classes.active)
  const [selected, setSelected] = useState<Lifecycle>(Lifecycle.Draft)

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }
  const handleCloseDropDown = () => {
    setAnchorEl(null)
  }
  useEffect(() => {
    if (defaultValue) {
      if (defaultValue === Lifecycle.Active) {
        setClassName(classes.active)
      } else if (defaultValue === Lifecycle.Draft) {
        setClassName(classes.draft)
      } else if (defaultValue === Lifecycle.Suspended) {
        setClassName(classes.suspended)
      } else {
        setClassName(classes.active)
      }
    }
    // eslint-disable-next-line
  }, [defaultValue])

  return (
    <>
      <FormControl variant={'filled'} size={'medium'}>
        <Field
          name={name}
          render={(fieldRenderProps) => {
            const { input } = fieldRenderProps
            const finalValue = input.value ? input.value : 'Active'
            setSelected(finalValue)

            const onClickIcon: (lifeCycle: Lifecycle) => void = (lifeCycle) => {
              setSelected(lifeCycle)
              input.onChange(lifeCycle)
              handleCloseDropDown()
              onUpdateLifeCycle(lifeCycle)
            }

            /* const handleReset = () => {
              if (defaultValue != null) {
                setSelected(defaultValue)
              } else {
                setSelected(Lifecycle.Draft)
              }
              input.onChange(defaultValue)
              handleCloseDropDown()
            }*/

            return (
              <>
                <Button
                  id="lifecycle-button"
                  aria-controls="lifecycle-menu"
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  variant="contained"
                  disableElevation
                  disabled={disabled}
                  onClick={handleClick}
                  className={className}
                  endIcon={<ArrowDropDownIcon className={classes.iconDropDown} />}
                >
                  <span style={{ color: '#FFFFFF' }}>{selected}</span>
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleCloseDropDown}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem onClick={() => onClickIcon(Lifecycle.Active)} style={{ width: '150px' }}>
                    <FiberManualRecordIcon fontSize="small" className={classes.activeIcon} />
                    Active
                  </MenuItem>

                  <MenuItem onClick={() => onClickIcon(Lifecycle.Draft)} style={{ width: '150px' }}>
                    <FiberManualRecordIcon fontSize="small" className={classes.draftIcon} />
                    Draft
                  </MenuItem>

                  <MenuItem onClick={() => onClickIcon(Lifecycle.Suspended)} style={{ width: '155px' }}>
                    <FiberManualRecordIcon fontSize="small" className={classes.suspendedIcon} />
                    Suspended
                  </MenuItem>
                </Menu>
              </>
            )
          }}
          {...fieldProps}
        />
      </FormControl>
    </>
  )
}

export default LifecycleSelect
