import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { PARTNER_CAMPAIGNS_API_URL } from './types'

export const fetchCampaigns = createAsyncThunk(
  'campaign/fetchCampaign',
  async (partnerId: string, { getState, requestId }) => {
    // @ts-ignore
    const { currentRequestId, loading } = getState().campaignsReducer
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }
    const asyncResult = await axios.get('/reward-service' + PARTNER_CAMPAIGNS_API_URL.replace(':partnerId', partnerId))
    return asyncResult.data
  },
)

export const fetchCampaignById = createAsyncThunk(
  'campaign/fetchCampaignById',
  async ({ partnerId, campaignId }: { partnerId: string; campaignId: string }, { getState, requestId }) => {
    // @ts-ignore
    const { currentRequestId, loading } = getState().campaignsReducer
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }
    const asyncResult = await axios.get(
      '/reward-service' + PARTNER_CAMPAIGNS_API_URL.replace(':partnerId', partnerId) + '/' + campaignId,
    )
    return asyncResult.data
  },
)
