import makeStyles from '@mui/styles/makeStyles'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import homeIcon from '../../assets/svg/header-icon-home.svg'
import usersIcon from '../../assets/svg/header-icon-users.svg'
import avatarFox from '../../assets/svg/avatar-fox.svg'
import avatarWarthog from '../../assets/svg/avatar-warthog.svg'
import avatarBear from '../../assets/svg/avatar-bear.svg'
import avatarRacoon from '../../assets/svg/avatar-racoon.svg'
import avatarBeaver from '../../assets/svg/avatar-beaver.svg'
import avatarZebra from '../../assets/svg/avatar-zebra.svg'
import avatarOstrich from '../../assets/svg/avatar-ostrich.svg'
import avatarSkunk from '../../assets/svg/avatar-skunk.svg'
import avatarChipmunk from '../../assets/svg/avatar-chipmunk.svg'
import avatarDeer from '../../assets/svg/avatar-deer.svg'
import avatarPlatypus from '../../assets/svg/avatar-platypus.svg'
import avatarKoala from '../../assets/svg/avatar-koala.svg'
import avatarCatOrange from '../../assets/svg/avatar-cat-orange.svg'
import avatarDogBlack from '../../assets/svg/avatar-dog-black.svg'
import avatarCatBlack from '../../assets/svg/avatar-cat-black.svg'
import avatarCatWhite from '../../assets/svg/avatar-cat-white.svg'
import avatarDogWhite from '../../assets/svg/avatar-dog-white.svg'
import avatarWalrus from '../../assets/svg/avatar-walrus.svg'
import avatarFennecFox from '../../assets/svg/avatar-fennec-fox.svg'
import avatarElephant from '../../assets/svg/avatar-elephant.svg'
import avatarPolarBear from '../../assets/svg/avatar-polar-bear.svg'
import avatarPenguin from '../../assets/svg/avatar-penguin.svg'
import React from 'react'
import { useNavigate } from 'react-router'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  containedButton: {
    fontFamily: 'Nunito Sans',
    fontWeight: 'bold!important',
    textTransform: 'none',
    fontSize: '0.875rem!important',
    borderRadius: '6px',
    borderWidth: '2px',
    letterSpacing: '0',
    color: '#ffffff!important',
    height: '55px',
    borderColor: '#147AC6!important',
    backgroundColor: '#147AC6!important',
    paddingLeft: '50px!important',
    paddingRight: '50px!important',
    marginLeft: '10px!important',
  },
  outlinedButton: {
    fontFamily: 'Nunito Sans',
    fontWeight: 'bold',
    textTransform: 'none',
    fontSize: '0.875rem!important',
    borderRadius: '6px',
    borderWidth: '2px',
    letterSpacing: '0',
    color: '#147AC6!important',
    height: '55px',
    width: '130px',
    borderColor: '#147AC6!important',
  },
  gridNoTopMargin: {
    paddingBottom: '0 !important',
  },
  gridNoMargin: {
    paddingTop: '0 !important',
    paddingBottom: '0 !important',
  },
  slideWrapper: {
    backgroundColor: '#eeeeee',
    paddingLeft: '5% !important',
    paddingRight: '5% !important',
    paddingTop: '10px',
    marginTop: '0px',
    textAlign: 'center',
  },
}))

interface IProps {}

const ConsoleStaffPin: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const navigate = useNavigate()
  return (
    <>
      <div id="wrapper" className="stations-screen">
        <div id="header-wrapper" className="landing-page-header">
          <div id="header">
            <div className="home-icon" onClick={() => navigate('/home')}>
              <img src={homeIcon} alt={'user-icon'} />
            </div>
            <a className="users-icon" href="/home">
              <img src={usersIcon} alt="home" />
            </a>
            <div className="header-right">
              <div className="page-title">
                <p>Station:</p>
                <h3>Ladies Fashion</h3>
              </div>
              <a id="valido-logo" href="/home">
                <span>Valido</span>
              </a>
            </div>
          </div>
        </div>
        <div id="main">
          <div className="col-10">
            <h1 className="text-blue has-icon padding-small stations">Staff Pin Reset</h1>

            <div className="reset-pin-screen">
              <div className="left-column">
                <h3 className="text-teal no-icon" style={{ marginTop: '20px' }}>
                  Enter One Time Pin
                </h3>
                <TextField
                  inputProps={{ className: 'consoleInput' }}
                  id="outlined-basic"
                  label=""
                  InputLabelProps={{ className: 'consoleLabel' }}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                />

                <h3 className="text-teal no-icon">Enter New Pin</h3>
                <TextField
                  inputProps={{ className: 'consoleInput' }}
                  id="outlined-basic"
                  label=""
                  InputLabelProps={{ className: 'consoleLabel' }}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                />

                <h3 className="text-teal no-icon">Confirm Pin</h3>
                <TextField
                  inputProps={{ className: 'consoleInput' }}
                  id="outlined-basic"
                  label=""
                  InputLabelProps={{ className: 'consoleLabel' }}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                />
              </div>

              <div className="right-column">
                <div className="pin-calc">
                  <div className="pin-calculator">
                    <div className="calculator">
                      <div className="number">
                        <span>1</span>
                      </div>
                      <div className="number">
                        <span>2</span>
                      </div>
                      <div className="number">
                        <span>3</span>
                      </div>
                      <div className="number">
                        <span>4</span>
                      </div>
                      <div className="number">
                        <span>5</span>
                      </div>
                      <div className="number">
                        <span>6</span>
                      </div>
                      <div className="number">
                        <span>7</span>
                      </div>
                      <div className="number">
                        <span>8</span>
                      </div>
                      <div className="number">
                        <span>9</span>
                      </div>
                      <div className="number text-button">
                        <span>Del</span>
                      </div>
                      <div className="number">
                        <span>0</span>
                      </div>
                      <div className="number text-button">
                        <span>Clear</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="choose-your-avatar">
              <h3 className="text-teal no-icon" style={{ paddingBottom: '10px' }}>
                Choose your Avatar
              </h3>
              <ul className="avatar-choices">
                <li className="avatar-fox">
                  <a href="/home">
                    <img src={avatarFox} alt="home" />
                  </a>
                </li>
                <li className="avatar-warthog">
                  <a href="/home">
                    <img src={avatarWarthog} alt="home" />
                  </a>
                </li>
                <li className="avatar-bear">
                  <a href="/home">
                    <img src={avatarBear} alt="home" />
                  </a>
                </li>
                <li className="avatar-racoon">
                  <a href="/home">
                    <img src={avatarRacoon} alt="home" />
                  </a>
                </li>
                <li className="avatar-beaver">
                  <a href="/home">
                    <img src={avatarBeaver} alt="home" />
                  </a>
                </li>
                <li className="avatar-zebra">
                  <a href="/home">
                    <img src={avatarZebra} alt="home" />
                  </a>
                </li>
                <li className="avatar-ostrich">
                  <a href="/home">
                    <img src={avatarOstrich} alt="home" />
                  </a>
                </li>
                <li className="avatar-skunk">
                  <a href="/home">
                    <img src={avatarSkunk} alt="home" />
                  </a>
                </li>
                <li className="avatar-chipmunk">
                  <a href="/home">
                    <img src={avatarChipmunk} alt="home" />
                  </a>
                </li>
                <li className="avatar-deer">
                  <a href="/home">
                    <img src={avatarDeer} alt="home" />
                  </a>
                </li>
                <li className="avatar-platypus">
                  <a href="/home">
                    <img src={avatarPlatypus} alt="home" />
                  </a>
                </li>
                <li className="avatar-koala">
                  <a href="/home">
                    <img src={avatarKoala} alt="home" />
                  </a>
                </li>
                <li className="avatar-cat-orange">
                  <a href="/home">
                    <img src={avatarCatOrange} alt="home" />
                  </a>
                </li>
                <li className="avatar-dog-black">
                  <a href="/home">
                    <img src={avatarDogBlack} alt="home" />
                  </a>
                </li>
                <li className="avatar-cat-black">
                  <a href="/home">
                    <img src={avatarCatBlack} alt="home" />
                  </a>
                </li>
                <li className="avatar-cat-white">
                  <a href="/home">
                    <img src={avatarCatWhite} alt="home" />
                  </a>
                </li>
                <li className="avatar-dog-white">
                  <a href="/home">
                    <img src={avatarDogWhite} alt="home" />
                  </a>
                </li>
                <li className="avatar-walrus">
                  <a href="/home">
                    <img src={avatarWalrus} alt="home" />
                  </a>
                </li>
                <li className="avatar-fennec-fox">
                  <a href="/home">
                    <img src={avatarFennecFox} alt="home" />
                  </a>
                </li>
                <li className="avatar-elephant">
                  <a href="/home">
                    <img src={avatarElephant} alt="home" />
                  </a>
                </li>
                <li className="avatar-polar-bear">
                  <a href="/home">
                    <img src={avatarPolarBear} alt="home" />
                  </a>
                </li>
                <li className="avatar-penguin">
                  <a href="/home">
                    <img src={avatarPenguin} alt="home" />
                  </a>
                </li>
              </ul>
            </div>

            <div className="button-row no-margin-top" style={{ flexDirection: 'row' }}>
              <div style={{ width: '50%' }}>
                <Button
                  className={classes.outlinedButton}
                  variant="outlined"
                  color="secondary"
                  size="large"
                  disableElevation
                  style={{ width: '170px' }}
                >
                  Cancel
                </Button>
              </div>
              <div style={{ width: '50%' }}>
                <Button
                  className={classes.containedButton}
                  variant="contained"
                  color="secondary"
                  size="large"
                  disableElevation
                  style={{ width: '170px' }}
                >
                  Done
                </Button>
              </div>
            </div>
          </div>

          <div className="col-2"></div>
        </div>
      </div>
    </>
  )
}

export default ConsoleStaffPin
