import { Box, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import Button from '@mui/material/Button'
import React, { Dispatch, SetStateAction } from 'react'
import creditBGIdle from '../../../assets/svg/button-credit-bg-idle.svg'
import creditBGActive from '../../../assets/svg/button-credit-bg-active.svg'
import PinPad from '../../../components/ui/PinPad'
import creditIcon from '../../../assets/svg/button-icon-credit.svg'
import refreshIcon from '../../../assets/svg/button-icon-refresh.svg'
import cancelIcon from '../../../assets/svg/button-icon-cancel.svg'
import docketBlue from '../../../assets/svg/button-docket-blue.svg'
import docketYellow from '../../../assets/svg/button-docket-yellow.svg'
import docketRed from '../../../assets/svg/button-docket-red.svg'
import docketGreen from '../../../assets/svg/button-docket-green.svg'
import lookupIcon from '../../../assets/svg/button-icon-lookup.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  outlinedButton: {
    fontFamily: 'Nunito Sans',
    fontWeight: 'bold',
    textTransform: 'none',
    fontSize: '0.875rem!important',
    borderRadius: '6px',
    borderWidth: '2px',
    letterSpacing: '0',
    color: '#147AC6!important',
    height: '40px',
    width: '91px',
    borderColor: '#147AC6!important',
  },
  containedButton: {
    fontFamily: 'Nunito Sans',
    fontWeight: 'bold!important',
    textTransform: 'none',
    fontSize: '0.875rem!important',
    borderRadius: '6px',
    borderWidth: '2px',
    letterSpacing: '0',
    color: '#ffffff!important',
    height: '40px',
    borderColor: '#147AC6!important',
    backgroundColor: '#147AC6!important',
    paddingLeft: '40px!important',
    paddingRight: '40px!important',
    marginLeft: '10px!important',
    width: '85px',
  },
  loadModal: {
    background: 'white',
    padding: '0 20px 20px 20px',
    borderRadius: '6px',
    width: '400px',
  },
  selectedBorder: {
    border: '3px solid #FC00FC !important',
  },
  creditButton: {
    backgroundImage: `url(${creditBGIdle})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: '97%',
    fontSize: '1.25rem!important',
    fontWeight: '800!important',
    backgroundColor: '#FAFBFB!important',
    border: '2px solid #FAFBFB!important',
    alignItems: 'flex-start!important',
    borderRadius: '12px!important',
    '&:hover': {
      border: '2px solid #ff00fc!important',
      backgroundImage: `url(${creditBGActive})`,
    },
  },

  transIDBlueButton: {
    backgroundImage: `url(${docketBlue})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: '97%',
    fontSize: '1.625rem!important',
    fontWeight: '600!important',
    backgroundColor: '#FAFBFB!important',
    border: '2px solid #FAFBFB!important',
    borderRadius: '12px!important',
    '&:hover': {
      border: '2px solid #ff00fc!important',
    },
  },
  transIDYellowButton: {
    backgroundImage: `url(${docketYellow})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: '97%',
    fontSize: '1.625rem!important',
    fontWeight: '600!important',
    backgroundColor: '#FAFBFB!important',
    border: '2px solid #FAFBFB!important',
    borderRadius: '12px!important',
    '&:hover': {
      border: '2px solid #ff00fc!important',
    },
  },
  transIDRedButton: {
    backgroundImage: `url(${docketRed})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: '97%',
    fontSize: '1.625rem!important',
    fontWeight: '600!important',
    backgroundColor: '#FAFBFB!important',
    border: '2px solid #FAFBFB!important',
    borderRadius: '12px!important',
    '&:hover': {
      border: '2px solid #ff00fc!important',
    },
  },
  transIDGreenButton: {
    backgroundImage: `url(${docketGreen})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: '97%',
    fontSize: '1.625rem!important',
    fontWeight: '600!important',
    backgroundColor: '#FAFBFB!important',
    border: '2px solid #FAFBFB!important',
    borderRadius: '12px!important',
    '&:hover': {
      border: '2px solid #ff00fc!important',
    },
  },
  outlinedLookupButton: {
    backgroundImage: `url(${lookupIcon})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    borderWidth: '2px!important',
    letterSpacing: '0',
    color: '#147AC6!important',
    borderColor: '#147AC6!important',
    width: '84px',
    '&:disabled': {
      opacity: '0.3',
    },
  },
  outlinedCreditButton: {
    backgroundImage: `url(${creditIcon})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '30px center',
    fontSize: '1.375rem!important',
    borderWidth: '2px!important',
    letterSpacing: '0',
    color: '#049B0E!important',
    borderColor: '#049B0E!important',
    width: '225px',
    height: '75px',
    textIndent: '50px',
    marginBottom: '10px!important',
    '&:disabled': {
      opacity: '0.3',
    },
  },
  outlinedRefreshButton: {
    backgroundImage: `url(${refreshIcon})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '30px center',
    fontSize: '1.375rem!important',
    borderWidth: '2px!important',
    letterSpacing: '0',
    color: '#FF8900!important',
    borderColor: '#FF8900!important',
    width: '225px',
    height: '60px',
    textIndent: '10px',
    marginBottom: '10px!important',
    '&:disabled': {
      opacity: '0.3',
    },
  },
  outlinedCancelButton: {
    backgroundImage: `url(${cancelIcon})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '30px center',
    fontSize: '1.375rem!important',
    borderWidth: '2px!important',
    letterSpacing: '0',
    color: '#F52F2F!important',
    borderColor: '#F52F2F!important',
    width: '225px',
    height: '60px',
    textIndent: '0px',
    marginBottom: '10px!important',
    '&:disabled': {
      opacity: '0.3',
    },
  },
  gridNoTopMargin: {
    paddingBottom: '0 !important',
  },
  gridNoMargin: {
    paddingTop: '0 !important',
    paddingBottom: '0 !important',
  },
  slideWrapper: {
    backgroundColor: '#eeeeee',
    paddingLeft: '5% !important',
    paddingRight: '5% !important',
    paddingTop: '10px',
    marginTop: '0px',
    textAlign: 'center',
  },
}))

interface IProps {
  display: boolean
  setHide: Dispatch<SetStateAction<boolean>>
  loadCreditWithPin: (event: any) => void
  userPin: string
  setUserPin: Dispatch<SetStateAction<string>>
}

const PinPadModel: React.FC<IProps> = (props) => {
  const { display, setHide, userPin, setUserPin, loadCreditWithPin } = props
  const classes = useStyles()

  const onClickHideDocketModel = (event: any) => {
    event.preventDefault()
    setHide(false)
  }

  return (
    <div className={display ? 'modalOverlayBG' : 'modalOverlayBGHide'}>
      <Box className={classes.loadModal}>
        <h3 className="text-teal has-icon cashier-code">Cashier Code</h3>
        <div className="inner-row1 calc-row1">
          <PinPad userPin={userPin} setUserPin={setUserPin} disable={false} />
        </div>
        <Grid item xs={12} style={{ paddingTop: '16px', textAlign: 'right', paddingRight: 22 }}>
          <Button
            className={classes.outlinedButton}
            variant="outlined"
            color="primary"
            size="large"
            disableElevation
            onClick={(event) => onClickHideDocketModel(event)}
          >
            Cancel
          </Button>
          <Button
            className={classes.containedButton}
            variant="contained"
            color="primary"
            size="large"
            disableElevation
            disabled={userPin.length < 4}
            onClick={(event) => loadCreditWithPin(event)}
          >
            OK
          </Button>
        </Grid>
      </Box>
    </div>
  )
}

export default PinPadModel
