import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialFlow, RewardFlow, RewardFlowState } from './types'

const initialState: RewardFlowState = {
  rewardFlow: initialFlow,
}

const rewardFlowSlice = createSlice({
  name: 'rewardsFlowReducer',
  initialState,
  reducers: {
    initFlow: (state, action: PayloadAction<RewardFlow>) => {
      state.rewardFlow = action.payload
    },
    updateFlow: (state, action: PayloadAction<RewardFlow>) => {
      state.rewardFlow = action.payload
    },
    resetFlow: (state) => {
      state.rewardFlow = initialFlow
    },
  },
})

export const { initFlow, updateFlow, resetFlow } = rewardFlowSlice.actions

export default rewardFlowSlice.reducer
