import React from 'react'
import { Container } from '@mui/material'
import { Outlet } from 'react-router-dom'
import ToastNotification from '../components/ui/ToastNotification'

interface IProps {
  children?: React.ReactNode
}

const PublicLayout: React.FC<IProps> = (props) => {
  const { children } = props

  return (
    <>
      <Container className="loginBG">
        <ToastNotification />
        <Outlet />
        {children}
      </Container>
    </>
  )
}
export default PublicLayout
