import makeStyles from '@mui/styles/makeStyles'
import Button from '@mui/material/Button'
import homeIcon from '../../assets/svg/header-icon-home.svg'
import signOnIcon from '../../assets/svg/button-icon-sign-on.svg'
import signOffIcon from '../../assets/svg/button-icon-sign-off.svg'
import { useNavigate } from 'react-router'
import { RootState, useAppDispatch, useAppSelector } from '../../store'
import { shallowEqual, useSelector } from 'react-redux'
import { selectPartnerId } from '../../store/reducers/auth/types'
import React, { useEffect, useState } from 'react'
import { PartnerStaff } from '../../store/reducers/partner-staffs/types'
import { RewardFlow } from '../../store/reducers/rewards-flow/types'
import { updateFlow } from '../../store/reducers/rewards-flow/reducers'
import Loading from '../../components/ui/Loading'
import clsx from 'clsx'
import { fetchPartnerStaffs } from '../../store/reducers/partner-staffs/thunk'
import {
  fetchPartnerStaffStationSignOn,
  postPartnerStaffStationSignOn,
  postPartnerStaffStationSignOff,
} from '../../store/reducers/station-staff/thunk'
import { selectPartnerStaffs, selectPartnerStaffsLoading } from '../../store/reducers/partner-staffs/reducers'
import {
  selectLoadingSignOff,
  selectPartnerStaffStationSign,
  selectPartnerStaffStationSignLoading,
  selectSignOffSuccess,
  selectSignOnSuccess,
} from '../../store/reducers/station-staff/reducers'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  containedButton: {
    fontFamily: 'Nunito Sans',
    fontWeight: 'bold!important',
    textTransform: 'none',
    fontSize: '0.875rem!important',
    borderRadius: '6px',
    borderWidth: '2px',
    letterSpacing: '0',
    color: '#ffffff!important',
    height: '55px',
    borderColor: '#147AC6!important',
    backgroundColor: '#147AC6!important',
    paddingLeft: '50px!important',
    paddingRight: '50px!important',
    marginLeft: '10px!important',
  },
  outlinedButton: {
    fontFamily: 'Nunito Sans',
    fontWeight: 'bold',
    textTransform: 'none',
    fontSize: '0.875rem!important',
    borderRadius: '6px',
    borderWidth: '2px',
    letterSpacing: '0',
    color: '#147AC6!important',
    height: '55px',
    width: '130px',
    borderColor: '#147AC6!important',
  },
  resetPinButton: {
    fontFamily: 'Nunito Sans',
    fontWeight: 'bold!important',
    textTransform: 'none',
    fontSize: '0.875rem!important',
    borderRadius: '6px',
    borderWidth: '2px',
    letterSpacing: '0',
    color: '#ffffff!important',
    height: '55px',
    borderColor: '#ff8900!important',
    backgroundColor: '#ff8900!important',
    paddingLeft: '35px!important',
    paddingRight: '35px!important',
    marginLeft: '10px!important',
  },
  signOnButton: {
    backgroundImage: `url(${signOnIcon})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '20px center',
    backgroundSize: '21px',
    fontFamily: 'Nunito Sans',
    fontWeight: 'bold!important',
    textTransform: 'none',
    fontSize: '0.875rem!important',
    borderRadius: '6px',
    borderWidth: '2px',
    letterSpacing: '0',
    color: '#ffffff!important',
    height: '55px',
    borderColor: '#05A718!important',
    backgroundColor: '#05A718!important',
    paddingLeft: '40px!important',
    paddingRight: '29px!important',
    marginLeft: '10px!important',
    textIndent: '10px',
  },
  signOffButton: {
    backgroundImage: `url(${signOffIcon})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '20px center',
    backgroundSize: '21px',
    fontFamily: 'Nunito Sans',
    fontWeight: 'bold!important',
    textTransform: 'none',
    fontSize: '0.875rem!important',
    borderRadius: '6px',
    borderWidth: '2px',
    letterSpacing: '0',
    color: '#ffffff!important',
    height: '55px',
    borderColor: '#CE0303!important',
    backgroundColor: '#CE0303!important',
    paddingLeft: '40px!important',
    paddingRight: '28px!important',
    marginLeft: '10px!important',
    textIndent: '10px',
  },
  gridNoTopMargin: {
    paddingBottom: '0 !important',
  },
  gridNoMargin: {
    paddingTop: '0 !important',
    paddingBottom: '0 !important',
  },
  slideWrapper: {
    backgroundColor: '#eeeeee',
    paddingLeft: '5% !important',
    paddingRight: '5% !important',
    paddingTop: '10px',
    marginTop: '0px',
    textAlign: 'center',
  },
  selectedBorder: {
    border: '2px solid #FC00FC !important',
  },
}))

export default function SelectStaff() {
  const classes = useStyles()

  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const partnerId = useSelector(selectPartnerId)

  const [selectedStaffToSignOn, setSelectedStaffToSignOn] = useState<PartnerStaff | null>(null)
  const [selectedSignedOnStaff, setSelectedSignedOnStaff] = useState<PartnerStaff | null>(null)
  const [filteredPartnerStaffs, setFilteredPartnerStaff] = useState<PartnerStaff[]>([])

  const partnerStaffs = useAppSelector(selectPartnerStaffs, shallowEqual)
  const loadingPartnerStaff = useAppSelector(selectPartnerStaffsLoading, shallowEqual)

  const signedOnUsers = useAppSelector(selectPartnerStaffStationSign, shallowEqual)
  const loadingSignOnUsers = useAppSelector(selectPartnerStaffStationSignLoading, shallowEqual)

  const signOffSuccess = useAppSelector(selectSignOffSuccess, shallowEqual)
  const signOnSuccess = useAppSelector(selectSignOnSuccess, shallowEqual)
  const loadingSignOnSuccess = useAppSelector(selectLoadingSignOff, shallowEqual)
  const loadingSignOnOffSuccess = useAppSelector(selectLoadingSignOff, shallowEqual)

  const { rewardFlow } = useSelector(
    (state: RootState) => ({
      rewardFlow: state.rewardsFlowReducer.rewardFlow,
    }),
    shallowEqual,
  )

  useEffect(() => {
    if (partnerId) {
      dispatch(fetchPartnerStaffs(partnerId))
    }
  }, [dispatch, partnerId])

  useEffect(() => {
    if (!rewardFlow.selectedStation && !rewardFlow.securityType) {
      navigate('/console/stations')
    }
  }, [rewardFlow, navigate])

  useEffect(() => {
    if (partnerStaffs && signedOnUsers) {
      let filteredPartnerStaffs: PartnerStaff[] | undefined = partnerStaffs.filter(
        (a) => !signedOnUsers.some((b) => a.id === b.staffId),
      )
      if (filteredPartnerStaffs) {
        setFilteredPartnerStaff(filteredPartnerStaffs)
      } else {
        setFilteredPartnerStaff([])
      }
    }
  }, [partnerStaffs, signedOnUsers])

  useEffect(() => {
    if (partnerId && rewardFlow.selectedStation && rewardFlow.selectedStation.id && (signOnSuccess || signOffSuccess)) {
      let stationId: string = rewardFlow.selectedStation.id
      dispatch(fetchPartnerStaffStationSignOn({ partnerId, stationId }))
      if (signOnSuccess) {
        setSelectedSignedOnStaff(selectedStaffToSignOn)
        setSelectedStaffToSignOn(null)
      }
      if (signOffSuccess) {
        setSelectedSignedOnStaff(null)
      }
    }
    // eslint-disable-next-line
  }, [partnerId, rewardFlow, signOnSuccess, signOffSuccess])

  useEffect(() => {
    if (partnerId && rewardFlow.selectedStation && rewardFlow.selectedStation.id && !signOnSuccess && !signOffSuccess) {
      let stationId: string = rewardFlow.selectedStation.id
      dispatch(fetchPartnerStaffStationSignOn({ partnerId, stationId }))
    }
  }, [dispatch, partnerId, rewardFlow, signOnSuccess, signOffSuccess])

  const onClickSignedOffStaff = (row: PartnerStaff) => {
    if (row.id === selectedStaffToSignOn?.id) {
      setSelectedStaffToSignOn(null)
    } else {
      setSelectedStaffToSignOn(row)
    }
  }

  const onClickSignedOnStaff = (row: PartnerStaff | undefined) => {
    if (row) {
      if (row.id === selectedSignedOnStaff?.id) {
        setSelectedSignedOnStaff(null)
      } else {
        setSelectedSignedOnStaff(row)
      }
    }
  }

  const onClickSignedOn = (event: any) => {
    event.preventDefault()
    if (partnerId && rewardFlow.selectedStation && selectedStaffToSignOn) {
      let stationId: string = rewardFlow.selectedStation.id
      let staffId: string = selectedStaffToSignOn.id
      dispatch(postPartnerStaffStationSignOn({ partnerId, stationId, staffId }))
    }
  }

  const onClickSignedOff = (event: any) => {
    event.preventDefault()
    if (partnerId && rewardFlow.selectedStation && selectedSignedOnStaff) {
      let stationId: string = rewardFlow.selectedStation.id
      let staffId: string = selectedSignedOnStaff.id
      dispatch(postPartnerStaffStationSignOff({ partnerId, stationId, staffId }))
    }
  }

  const onClickCancel = () => {
    navigate('/console/stations')
  }

  const onClickNext = (event: any) => {
    event.preventDefault()
    if (rewardFlow) {
      let rewardFlowToUpdate: RewardFlow = {
        selectedStation: rewardFlow.selectedStation,
        securityType: rewardFlow.securityType,
        signedOnUser: selectedSignedOnStaff,
      }

      dispatch(updateFlow(rewardFlowToUpdate))
      navigate('/console/select-docket')
    }
  }

  return (
    <>
      <div id="wrapper" className="stations-screen">
        {(loadingPartnerStaff || loadingSignOnOffSuccess || loadingSignOnUsers || loadingSignOnSuccess) && <Loading />}
        <div id="header-wrapper" className="landing-page-header">
          <div id="header">
            <div className="home-icon" onClick={() => navigate('/home')}>
              <img src={homeIcon} alt={'user-icon'} />
            </div>
            <div className="header-right">
              <div className="page-title">
                <p>Station:</p>
                <h3>{rewardFlow?.selectedStation?.name}</h3>
              </div>
              <div id="valido-logo" onClick={() => navigate('/home')}>
                <span>Valido</span>
              </div>
            </div>
          </div>
        </div>
        <div id="main">
          <div className="col-10">
            <h1 className="text-blue has-icon padding-small stations" style={{ marginTop: 10 }}>
              Staff
            </h1>

            <h3 className="text-teal has-icon signed-on" style={{ marginTop: '20px' }}>
              Signed On
            </h3>

            <div className="grid staff-buttons">
              {signedOnUsers?.map((staffs) => {
                let selectedStaff: PartnerStaff | undefined = partnerStaffs?.find((row) => row.id === staffs.staffId)
                return (
                  <div
                    className={clsx('avatar-button', {
                      [classes.selectedBorder]: selectedSignedOnStaff?.id === staffs.staffId,
                    })}
                    key={staffs.staffId}
                    onClick={() => onClickSignedOnStaff(selectedStaff)}
                  >
                    <div className="content-center-avatar">
                      <img src={'/' + selectedStaff?.avatar} className="avatar-img" alt={selectedStaff?.firstName} />
                    </div>
                    <div className="content-bottom-center-avatar">
                      {selectedStaff?.firstName}&nbsp;{selectedStaff?.lastName}
                    </div>
                  </div>
                )
              })}
            </div>

            <h3 className="text-teal has-icon signed-off">Signed Off</h3>

            <div className="grid staff-buttons scroll-box">
              {filteredPartnerStaffs?.map((row) => (
                <>
                  <div
                    className={clsx('avatar-button', {
                      [classes.selectedBorder]: selectedStaffToSignOn?.id === row.id,
                    })}
                    key={row.id}
                    onClick={() => onClickSignedOffStaff(row)}
                  >
                    <div className="content-center-avatar">
                      <img src={'/' + row?.avatar} className="avatar-img" alt={row?.firstName} />
                    </div>
                    <div className="content-bottom-center-avatar">
                      {row?.firstName}&nbsp;{row?.lastName}
                    </div>
                  </div>
                </>
              ))}
            </div>
            <div className="overlay-fade"></div>
            <div className="button-row no-margin-top" style={{ flexDirection: 'row' }}>
              <div style={{ width: '20%' }}>
                <Button
                  className={classes.outlinedButton}
                  variant="outlined"
                  color="secondary"
                  size="large"
                  disableElevation
                  onClick={() => onClickCancel()}
                >
                  Cancel
                </Button>
              </div>
              <div style={{ width: '80%' }}>
                <Button
                  className={classes.resetPinButton}
                  variant="contained"
                  color="secondary"
                  size="large"
                  disableElevation
                >
                  Reset Pin
                </Button>
                <Button
                  className={classes.signOnButton}
                  variant="contained"
                  color="secondary"
                  size="large"
                  disableElevation
                  disabled={selectedStaffToSignOn === null}
                  onClick={(e) => onClickSignedOn(e)}
                >
                  Sign On
                </Button>
                <Button
                  className={classes.signOffButton}
                  variant="contained"
                  color="secondary"
                  size="large"
                  disableElevation
                  disabled={selectedSignedOnStaff === null}
                  onClick={(e) => onClickSignedOff(e)}
                >
                  Sign Off
                </Button>
                <Button
                  className={classes.containedButton}
                  variant="contained"
                  color="secondary"
                  size="large"
                  disableElevation
                  disabled={!signedOnUsers || (signedOnUsers && signedOnUsers.length === 0)}
                  onClick={(e) => onClickNext(e)}
                >
                  Next
                </Button>
              </div>
            </div>
          </div>

          <div className="col-2"></div>
        </div>
      </div>
    </>
  )
}
