import { AuthState } from './types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import firebase from 'firebase/compat'

const initialState: AuthState = {
  initialized: false,
  loading: false,
  loggedIn: false,
  user: null,
  error: null,
}

const authReducerSlice = createSlice({
  name: 'authReducer',
  initialState,
  reducers: {
    setLoggedOn: (state, action: PayloadAction<firebase.User>) => {
      state.user = action.payload
      state.loggedIn = true
    },
    setLoggedOff: (state) => {
      state.user = null
      state.loggedIn = false
    },
  },
})

export const { setLoggedOn, setLoggedOff } = authReducerSlice.actions

export default authReducerSlice.reducer
