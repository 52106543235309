import { Order, TableColumn } from './types'
import React from 'react'
import { TableCell, TableHead, TableSortLabel } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

const style = makeStyles(() =>
  createStyles({
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }),
)

interface CustomTableHeadProps {
  onRequestSort?: (event: React.MouseEvent<unknown>, property: string | undefined) => void
  sortDirection?: Order
  sortBy?: string
  columns: TableColumn[]
}

const CustomTableHead: React.FC<CustomTableHeadProps> = (props) => {
  const { onRequestSort, sortDirection, sortBy, columns } = props
  const classes = style()

  const { t } = useTranslation()

  return (
    <TableHead>
      {columns.map((column) => (
        <TableCell
          key={column.id}
          align={column.align}
          style={{ width: column.minWidth + '%', maxWidth: column.minWidth + '%' }}
          sortDirection={column.sortable === column.name ? 'asc' : false}
        >
          {column.sortable ? (
            <TableSortLabel
              active={sortBy === column.name}
              direction={sortBy === column.name ? sortDirection : 'asc'}
              onClick={(event) => (onRequestSort ? onRequestSort(event, column.name) : null)}
              IconComponent={KeyboardArrowDownIcon}
            >
              {t(column.label)}
              {sortBy === column.name ? (
                <span className={classes.visuallyHidden}>
                  {sortDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          ) : (
            <>{t(column.label)}</>
          )}
        </TableCell>
      ))}
    </TableHead>
  )
}

export default CustomTableHead
