import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React from 'react'
import { IdName } from '../../store/reducers/common/types'

export interface IProps {
  name: string
  label: string
  data: IdName[]
  onChange: (event: any) => void
  hideNone?: boolean
}

const FormSelect: React.FC<IProps> = (props) => {
  const { name, label, data, onChange, hideNone } = props

  return (
    <FormControl variant="outlined" fullWidth size={'small'} style={{ marginTop: 8 }}>
      <InputLabel id="accessModeId-input" style={{ marginTop: 7 }}>
        {label}
      </InputLabel>
      <Select
        label={label}
        fullWidth
        id={name}
        name={name}
        variant="outlined"
        style={{ height: 55 }}
        margin="dense"
        required={false}
        MenuProps={{
          disableScrollLock: true,
        }}
        onChange={onChange}
      >
        {!hideNone && (
          <MenuItem value="" key="">
            None
          </MenuItem>
        )}

        {data
          ? data.map((item) => (
              <MenuItem value={item.id ? item.id : ''} key={item.id}>
                {item.name}
              </MenuItem>
            ))
          : null}
      </Select>
    </FormControl>
  )
}

export default FormSelect
