import React, { useEffect, useState } from 'react'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import { ClickAwayListener } from '@mui/material'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { ReactComponent as ErrorIcon } from '../../assets/svg/toast-icon.error.svg'
import { ReactComponent as SuccessIcon } from '../../assets/svg/toast-icon-right.svg'
import { RootState } from '../../store'
import { clearNotification } from '../../store/reducers/notifications/reducers'
import { NotificationType } from '../../store/reducers/notifications/types'

interface IProps {}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

const ToastNotification: React.FC<IProps> = (props) => {
  const [open, setOpen] = useState<boolean>(false)
  const [message, setMessage] = useState<string | null>(null)

  const dispatch = useDispatch()

  const { type, messages } = useSelector(
    (state: RootState) => ({
      type: state.notificationsReducer.type,
      messages: state.notificationsReducer.messages,
    }),
    shallowEqual,
  )

  useEffect(() => {
    if (messages && messages.length > 0) {
      setMessage(messages[0])
      setOpen(true)
    } else {
      dispatch(clearNotification())
    }
  }, [type, messages, dispatch])

  const handleClose = (event: any) => {
    setOpen(false)
  }

  const handleClickAway = () => {
    setOpen(false)
  }

  useEffect(() => {
    if (!open) {
      dispatch(clearNotification())
    }
  }, [open, dispatch])

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Snackbar open={open} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={6000}>
        <Alert
          onClose={handleClose}
          severity={type === NotificationType.ERROR ? 'error' : 'success'}
          iconMapping={{
            error: <ErrorIcon />,
            success: <SuccessIcon style={{ marginTop: 6 }} />,
          }}
        >
          {message}
        </Alert>
      </Snackbar>
    </ClickAwayListener>
  )
}
export default ToastNotification
