import { AppThunk } from '../../index'
import { appConfigError, appConfigRequest, appConfigResponse } from './reducers'

export const getAppConfigThunk =
  (appCode: string): AppThunk =>
  async (dispatch) => {
    dispatch(appConfigRequest())
    const appPath = '/_config/partner/' + appCode + '.json'
    console.debug('Loading app config from ' + appPath)
    fetch(appPath)
      .then((response) => response.json())
      .then((json) => dispatch(appConfigResponse(json)))
      .catch((error) => dispatch(appConfigError(error)))
  }
