import { createSlice } from '@reduxjs/toolkit'
import { PartnerStaffStationSignOnState } from './types'
import { fetchPartnerStaffStationSignOn, postPartnerStaffStationSignOff, postPartnerStaffStationSignOn } from './thunk'
import { RootState } from '../../index'

const initialState: PartnerStaffStationSignOnState = {
  success: false,
  error: null,
  partnerStaffStationSignOn: null,
  signOnSuccess: false,
  signOffSuccess: false,
  currentRequestId: undefined,
  loading: 'idle',
}

const partnerStaffStationSignOnSlice = createSlice({
  name: 'partnerStaffStationSignOnReducer',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchPartnerStaffStationSignOn.pending.type]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [fetchPartnerStaffStationSignOn.fulfilled.type]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.partnerStaffStationSignOn = action.payload
        state.currentRequestId = undefined
      }
    },
    [fetchPartnerStaffStationSignOn.rejected.type]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.error = action.error
        state.currentRequestId = undefined
      }
    },
    [postPartnerStaffStationSignOn.pending.type]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
        state.signOnSuccess = false
      }
    },
    [postPartnerStaffStationSignOn.fulfilled.type]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
        state.signOnSuccess = true
      }
    },
    [postPartnerStaffStationSignOn.rejected.type]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.error = action.error
        state.currentRequestId = undefined
        state.signOnSuccess = false
      }
    },
    [postPartnerStaffStationSignOff.pending.type]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
        state.signOffSuccess = false
      }
    },
    [postPartnerStaffStationSignOff.fulfilled.type]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
        state.signOffSuccess = true
      }
    },
    [postPartnerStaffStationSignOff.rejected.type]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.error = action.error
        state.currentRequestId = undefined
        state.signOffSuccess = false
      }
    },
  },
})

export const selectPartnerStaffStationSign = (state: RootState) =>
  state.partnerStaffStationSignOnReducer.partnerStaffStationSignOn
export const selectPartnerStaffStationSignLoading = (state: RootState) =>
  state.partnerStaffStationSignOnReducer.loading === 'pending'

export const selectSignOnSuccess = (state: RootState) => state.partnerStaffStationSignOnReducer.signOnSuccess
export const selectSignOffSuccess = (state: RootState) => state.partnerStaffStationSignOnReducer.signOffSuccess
export const selectLoadingSignOff = (state: RootState) => state.partnerStaffStationSignOnReducer.loading === 'pending'
export const selectLoadingSignOn = (state: RootState) => state.partnerStaffStationSignOnReducer.loading === 'pending'

export default partnerStaffStationSignOnSlice.reducer
