import React, { useEffect, useState } from 'react'
import homeIcon from '../../assets/svg/header-icon-home.svg'
import Loading from '../../components/ui/Loading'
import { Grid, Table, TableBody, TableCell, TableRow } from '@mui/material'
import CustomTableHead from '../../components/table/CustomTableHead'
import { useAppDispatch, useAppSelector } from '../../store'
import { shallowEqual, useSelector } from 'react-redux'
import { selectPartnerId } from '../../store/reducers/auth/types'
import { useNavigate } from 'react-router'
import usePagination from '../../hook/usePagination'
import { fetchStations } from './thunk'
import { stationListColumn } from './types'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import StatusCell from '../../components/ui/StatusCell'
import FormTextField from '../../components/form-filed/FormTextField'
import FormSelect from '../../components/form-filed/FormSelect'
import { lifeCycleIdNames } from '../../store/reducers/common/types'
import pdfIcon from '../../assets/images/pdf.png'
import { selectStations, selectStationsLoading } from './reducers'

interface IProps {}

const StationsList: React.FC<IProps> = (props) => {
  const dispatch = useAppDispatch()
  const partnerId = useSelector(selectPartnerId)
  const navigate = useNavigate()

  const [sortDirection, sortBy, handleRequestSort] = usePagination()
  const [openPdfDialog, setOpenDialog] = useState<boolean>(false)

  const stations = useAppSelector(selectStations, shallowEqual)
  const loading = useAppSelector(selectStationsLoading, shallowEqual)

  useEffect(() => {
    if (partnerId) {
      dispatch(fetchStations(partnerId))
    }
  }, [dispatch, partnerId])

  const handleRowClick = (event: React.MouseEvent<HTMLTableRowElement>) => {
    event.preventDefault()
    event.stopPropagation()
    navigate('' + event.currentTarget.dataset.id)
  }

  const handleBackClick = () => {
    navigate('/home')
  }

  const onChangeField = (event: any) => {}

  const handleChangeSelect = (event: any) => {}

  const openImage = (event: any) => {
    event.preventDefault()
    event.stopPropagation()
    console.log(openPdfDialog)
    setOpenDialog(true)
  }

  return (
    <div id="wrapper" className="stations-screen">
      {loading && <Loading />}
      <div id="header-wrapper" className="landing-page-header">
        <div id="header">
          <div className="home-icon" onClick={() => navigate('/home')}>
            <img src={homeIcon} alt={'user-icon'} />
          </div>
          <div className="nav-icons">
            <ArrowBackIosIcon fontSize={'large'} style={{ marginRight: 20 }} onClick={() => handleBackClick()} />
          </div>
          <div className="nav-text  pos-avatar">POS</div>
          <div className="header-right">
            <div id="valido-logo" onClick={() => navigate('/home')}>
              <span>Valido</span>
            </div>
          </div>
        </div>
      </div>

      <div id="main-no-grid">
        <div className="col-header">
          <Grid container spacing={1} style={{ marginBottom: 16 }}>
            <Grid item xs={10} md={10}>
              <div className="text-heading-pink">Stations</div>
            </Grid>

            <Grid
              item
              xs={2}
              md={2}
              style={{ textAlign: 'right', paddingRight: 6 }}
              onClick={() => navigate('/stations/new')}
            >
              <div className="text-heading-blue">+ Station</div>
            </Grid>

            <Grid item xs={4} md={4}>
              <FormTextField name="stationName" label="Station Name" onChange={onChangeField} />
            </Grid>

            <Grid item xs={4} md={4}>
              <FormTextField name="location" label="Location" onChange={onChangeField} />
            </Grid>

            <Grid item xs={2} md={2}>
              <FormTextField name="qrCode" label="QR Code" onChange={onChangeField} />
            </Grid>

            <Grid item xs={2} md={2}>
              <FormSelect label="Status" name="status" onChange={handleChangeSelect} data={lifeCycleIdNames} />
            </Grid>
          </Grid>

          <Table aria-label="tableTitle" size="medium">
            <CustomTableHead
              onRequestSort={handleRequestSort}
              sortDirection={sortDirection}
              sortBy={sortBy}
              columns={stationListColumn}
            />
            <TableBody>
              {stations && stations.length > 0 ? (
                stations.map((row) => (
                  <TableRow key={row.id} data-id={row.id} tabIndex={0} role="button" hover onClick={handleRowClick}>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.location}</TableCell>
                    <TableCell style={{ paddingLeft: 48 }}>
                      <img
                        src={pdfIcon}
                        alt={row.qrCode}
                        style={{ height: 14, width: 14 }}
                        onClick={(event) => openImage(event)}
                      />
                    </TableCell>
                    <TableCell>
                      <StatusCell status={row.lifecycle ? row.lifecycle.toString() : ''} />
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>No Record found</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  )
}

export default StationsList
