import { OfferState } from './types'
import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../index'
import { fetchOfferById, fetchOffers } from './thunk'

const initialState: OfferState = {
  success: false,
  error: null,
  offers: null,
  offer: null,
  loading: 'idle',
  currentRequestId: undefined,
}

const offerSliceReducer = createSlice({
  name: 'offerReducer',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchOffers.pending.type]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [fetchOffers.fulfilled.type]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.offers = action.payload
        state.currentRequestId = undefined
      }
    },
    [fetchOffers.rejected.type]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.error = action.error
        state.currentRequestId = undefined
      }
    },
    [fetchOfferById.pending.type]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [fetchOfferById.fulfilled.type]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.offer = action.payload
        state.currentRequestId = undefined
      }
    },
    [fetchOfferById.rejected.type]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.error = action.error
        state.currentRequestId = undefined
      }
    },
  },
})

export const selectOffers = (state: RootState) => state.offerReducer.offers
export const selectOffersLoading = (state: RootState) => state.offerReducer.loading === 'pending'
export const selectOffer = (state: RootState) => state.offerReducer.offer
export const selectUserOfferLoading = (state: RootState) => state.offerReducer.loading === 'pending'

export default offerSliceReducer.reducer
