import makeStyles from '@mui/styles/makeStyles'
import Button from '@mui/material/Button'
import homeIcon from '../../assets/svg/header-icon-home.svg'
import usersIcon from '../../assets/svg/header-icon-users.svg'
import lookupIcon from '../../assets/svg/button-icon-lookup.svg'
import creditIcon from '../../assets/svg/button-icon-credit.svg'
import refreshIcon from '../../assets/svg/button-icon-refresh.svg'
import cancelIcon from '../../assets/svg/button-icon-cancel.svg'
import docketBlue from '../../assets/svg/button-docket-blue.svg'
import docketYellow from '../../assets/svg/button-docket-yellow.svg'
import docketRed from '../../assets/svg/button-docket-red.svg'
import docketGreen from '../../assets/svg/button-docket-green.svg'
import creditBGActive from '../../assets/svg/button-credit-bg-active.svg'
import creditBGIdle from '../../assets/svg/button-credit-bg-idle.svg'
import { useNavigate } from 'react-router'
import { RootState, useAppDispatch } from '../../store'
import { shallowEqual, useSelector } from 'react-redux'
import { selectPartnerId } from '../../store/reducers/auth/types'
import React, { useEffect, useState } from 'react'
import {
  PARTNER_DOCKET_API_URL,
  PARTNER_TRANSACTION_API_URL,
  RewardTransactionDocketEvent,
  RewardTransactionDocketStatus,
  RewardTransactionRequest,
  SecurityType,
} from '../../store/reducers/rewards-flow/types'
import { SpendRule } from '../campaigns/types'
import axios from 'axios'
import Loading from '../../components/ui/Loading'
import docketBlank from '../../assets/svg/button-docket-bg.svg'
import { PartnerStaffStationSignOn } from '../../store/reducers/station-staff/types'
import { PartnerStaff } from '../../store/reducers/partner-staffs/types'
import clsx from 'clsx'
import { format2Decimal, toDisplayHoursOrMinutes } from '../../util/util'
import DocketTransactionModel from './model/docket-transaction-model'
import DocketCreditModel from './model/docket-credit-model'
import DocketNotificationModel from './model/docket-notification-model'
import DocketUsersModel from './model/docket-users-model'
import PinPadModel from './model/pin-pad--model'
import { Grid } from '@mui/material'
import { fetchPartnerStaffs } from '../../store/reducers/partner-staffs/thunk'
import { fetchPartnerStaffStationSignOn } from '../../store/reducers/station-staff/thunk'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  containedButton: {
    fontFamily: 'Nunito Sans',
    fontWeight: 'bold!important',
    textTransform: 'none',
    fontSize: '0.875rem!important',
    borderRadius: '50px',
    borderWidth: '2px',
    letterSpacing: '0',
    color: '#ffffff!important',
    height: '40px',
    borderColor: '#147AC6!important',
    backgroundColor: '#147AC6!important',
    paddingLeft: '40px!important',
    paddingRight: '40px!important',
    marginLeft: '10px!important',
    width: '85px',
  },
  outlinedButton: {
    fontFamily: 'Nunito Sans',
    fontWeight: 'bold',
    textTransform: 'none',
    fontSize: '0.875rem!important',
    borderRadius: '50px',
    borderWidth: '2px',
    letterSpacing: '0',
    color: '#147AC6!important',
    height: '40px',
    width: '85px',
    borderColor: '#147AC6!important',
  },
  transIDBlueButton: {
    backgroundImage: `url(${docketBlue})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: '97%',
    fontSize: '1.625rem!important',
    fontWeight: '600!important',
    backgroundColor: '#FAFBFB!important',
    border: '2px solid #FAFBFB!important',
    borderRadius: '12px!important',
    '&:hover': {
      border: '2px solid #ff00fc!important',
    },
  },
  transIDYellowButton: {
    backgroundImage: `url(${docketYellow})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: '97%',
    fontSize: '1.625rem!important',
    fontWeight: '600!important',
    backgroundColor: '#FAFBFB!important',
    border: '2px solid #FAFBFB!important',
    borderRadius: '12px!important',
    '&:hover': {
      border: '2px solid #ff00fc!important',
    },
  },
  transIDRedButton: {
    backgroundImage: `url(${docketRed})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: '97%',
    fontSize: '1.625rem!important',
    fontWeight: '600!important',
    backgroundColor: '#FAFBFB!important',
    border: '2px solid #FAFBFB!important',
    borderRadius: '12px!important',
    '&:hover': {
      border: '2px solid #ff00fc!important',
    },
  },
  transIDGreenButton: {
    backgroundImage: `url(${docketGreen})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: '97%',
    fontSize: '1.625rem!important',
    fontWeight: '600!important',
    backgroundColor: '#FAFBFB!important',
    border: '2px solid #FAFBFB!important',
    borderRadius: '12px!important',
    '&:hover': {
      border: '2px solid #ff00fc!important',
    },
  },
  creditButton: {
    backgroundImage: `url(${creditBGIdle})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: '97%',
    fontSize: '1.25rem!important',
    fontWeight: '800!important',
    backgroundColor: '#FAFBFB!important',
    border: '2px solid #FAFBFB!important',
    alignItems: 'flex-start!important',
    borderRadius: '12px!important',
    '&:hover': {
      border: '2px solid #ff00fc!important',
      backgroundImage: `url(${creditBGActive})`,
    },
  },
  outlinedLookupButton: {
    backgroundImage: `url(${lookupIcon})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    borderWidth: '2px!important',
    letterSpacing: '0',
    color: '#147AC6!important',
    borderColor: '#147AC6!important',
    width: '84px',
    '&:disabled': {
      opacity: '0.3',
    },
  },
  outlinedCreditButton: {
    backgroundImage: `url(${creditIcon})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '30px center',
    fontSize: '1.375rem!important',
    borderWidth: '2px!important',
    letterSpacing: '0',
    color: '#049B0E!important',
    borderColor: '#049B0E!important',
    textIndent: '50px',
    marginBottom: '10px!important',
    '&:disabled': {
      opacity: '0.3',
    },
  },
  outlinedRefreshButton: {
    backgroundImage: `url(${refreshIcon})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '30px center',
    fontSize: '1.375rem!important',
    borderWidth: '2px!important',
    letterSpacing: '0',
    color: '#FF8900!important',
    borderColor: '#FF8900!important',
    textIndent: '10px',
    marginBottom: '10px!important',
    '&:disabled': {
      opacity: '0.3',
    },
  },
  outlinedCancelButton: {
    backgroundImage: `url(${cancelIcon})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '30px center',
    fontSize: '1.375rem!important',
    borderWidth: '2px!important',
    letterSpacing: '0',
    color: '#F52F2F!important',
    borderColor: '#F52F2F!important',
    textIndent: '0px',
    marginBottom: '10px!important',
    '&:disabled': {
      opacity: '0.3',
    },
  },

  transIDBlankButton: {
    backgroundImage: `url(${docketBlank})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: '97%',
    fontSize: '1.625rem!important',
    fontWeight: '600!important',
    color: '#000000',
    backgroundColor: '#FAFBFB!important',
    border: '2px solid #FAFBFB!important',
    borderRadius: '12px!important',
    '&:hover': {
      border: '3px solid #ff00fc!important',
    },
  },
  selectedBorder: {
    border: '2px solid #FC00FC !important',
  },

  gridNoTopMargin: {
    paddingBottom: '0 !important',
  },
  gridNoMargin: {
    paddingTop: '0 !important',
    paddingBottom: '0 !important',
  },
  slideWrapper: {
    backgroundColor: '#eeeeee',
    paddingLeft: '5% !important',
    paddingRight: '5% !important',
    paddingTop: '10px',
    marginTop: '0px',
    textAlign: 'center',
  },
  loadModal: {
    background: 'white',
    padding: '0 20px 20px 20px',
    borderRadius: '6px',
    width: '820px',
  },
}))

export default function SelectDocket() {
  const classes = useStyles()

  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const partnerId = useSelector(selectPartnerId)

  const [loading, setLoading] = useState<boolean>(false)
  const [userPin, setUserPin] = useState<string>('')

  const [rewardTransactionDocketEvents, setRewardTransactionDocketEvents] = useState<RewardTransactionDocketEvent[]>([])

  const [selectedRewardTransaction, setSelectedRewardTransaction] = useState<RewardTransactionDocketEvent | null>(null)
  const [selectedSpendRule, setSelectedSpendRule] = useState<SpendRule | null>(null)
  const [selectedSignOnUser, setSelectedSignOnUser] = useState<PartnerStaffStationSignOn | null>(null)

  const [availableSpendRules, setAvailableSpendRuled] = useState<SpendRule[]>([])

  const [filteredRewardTransaction, setFilteredRewardTransaction] = useState<RewardTransactionDocketEvent[]>([])
  const [filteredSpendRules, setFilteredSpendRules] = useState<SpendRule[]>([])
  const [filteredSignedOnUsers, setFilteredSignedOnUsers] = useState<PartnerStaffStationSignOn[]>([])

  const [displayDocketModel, setDisplayDocketModel] = useState<boolean>(false)
  const [displayCreditModel, setDisplayCreditModel] = useState<boolean>(false)
  const [displayUserModel, setDisplayUserModel] = useState<boolean>(false)
  const [displayPinPadModel, setDisplayPinPadModel] = useState<boolean>(false)

  const [displayNotificationModel, setDisplayNotificationModel] = useState<boolean>(false)

  const [sseError, setSseError] = useState<string | null>(null)
  const [sseCount, setSseCount] = useState<number>(0)

  const { rewardFlow, signedOnUsers, partnerStaffs } = useSelector(
    (state: RootState) => ({
      rewardFlow: state.rewardsFlowReducer.rewardFlow,
      partnerStaffs: state.partnerStaffReducer.partnerStaffs,
      signedOnUsers: state.partnerStaffStationSignOnReducer.partnerStaffStationSignOn,
    }),
    shallowEqual,
  )

  useEffect(() => {
    if (!rewardFlow.selectedStation && !rewardFlow.securityType && !rewardFlow.signedOnUser) {
      navigate('/console/stations')
    }
  }, [rewardFlow, navigate])

  useEffect(() => {
    if (rewardFlow.signedOnUser && rewardFlow.signedOnUser.id && signedOnUsers) {
      let signedOnUser = signedOnUsers.find((row) => row.staffId === rewardFlow?.signedOnUser?.id)
      if (signedOnUser) {
        setSelectedSignOnUser(signedOnUser)
      }
    }
  }, [rewardFlow, signedOnUsers])

  useEffect(() => {
    if (partnerId && rewardFlow.selectedStation && rewardFlow.selectedStation.id && !loading) {
      setRewardTransactionDocketEvents([])
      setSelectedRewardTransaction(null)
      setSelectedSpendRule(null)
      setSseError(null)
      const sse = new EventSource(
        PARTNER_DOCKET_API_URL.replace(':partnerId', partnerId).replace(':stationId', rewardFlow.selectedStation.id),
      )
      const getRealtimeData = (ev: MessageEvent<any>) => {
        if (ev.data) {
          const received: RewardTransactionDocketEvent = JSON.parse(ev.data)
          if (received.status === RewardTransactionDocketStatus.Pending) {
            setRewardTransactionDocketEvents((prevState) => {
              const found = prevState.filter((docket) => docket.id === received.id)
              let result
              if (found.length === 0) {
                console.log('Adding pending docket ' + received.code)
                result = [...prevState, received]
              } else {
                console.log('Ignoring pending existing docket ' + received.code)
                result = prevState
              }
              return result
            })
          } else {
            console.log('Removing docket ' + received.code + ' with status ' + received.status)
            setRewardTransactionDocketEvents((items) => items.filter((docket) => docket.id !== received.id))
          }
        }
      }
      sse.addEventListener('docket', getRealtimeData)
      sse.onerror = () => {
        setSseError('Disconnected')
        sse.close()
      }
      return () => {
        sse.close()
      }
    }
  }, [rewardFlow, partnerId, loading, sseCount])

  useEffect(() => {
    let filteredSignedOn: PartnerStaffStationSignOn[] = []
    if (signedOnUsers) {
      Array.of(0, 1, 2, 3).map((rowIndex) => {
        let signOn: PartnerStaffStationSignOn | null = rowIndex < signedOnUsers.length ? signedOnUsers[rowIndex] : null

        if (signOn) {
          filteredSignedOn.push(signOn)
        }
        return ''
      })
    }
    setFilteredSignedOnUsers(filteredSignedOn)
  }, [signedOnUsers])

  useEffect(() => {
    let filteredTx: RewardTransactionDocketEvent[] = []
    if (rewardTransactionDocketEvents) {
      Array.of(0, 1, 2, 3).map((rowIndex) => {
        let rewardTx: RewardTransactionDocketEvent | null =
          rowIndex < rewardTransactionDocketEvents.length ? rewardTransactionDocketEvents[rowIndex] : null

        if (rewardTx) {
          filteredTx.push(rewardTx)
        }
        return ''
      })
    }
    setFilteredRewardTransaction(filteredTx)
  }, [rewardTransactionDocketEvents])

  useEffect(() => {
    if (selectedRewardTransaction) {
      if (
        selectedRewardTransaction &&
        selectedRewardTransaction.campaign &&
        selectedRewardTransaction.campaign.spendRules
      ) {
        if (selectedRewardTransaction.campaign.spendRules.length > 4) {
          setFilteredSpendRules(selectedRewardTransaction.campaign.spendRules.slice(0, 4))
        } else {
          setFilteredSpendRules(selectedRewardTransaction.campaign.spendRules)
        }
      }
      if (
        selectedRewardTransaction &&
        selectedRewardTransaction.campaign &&
        selectedRewardTransaction.campaign.spendRules
      ) {
        setAvailableSpendRuled(selectedRewardTransaction.campaign.spendRules)
      }
    }
  }, [selectedRewardTransaction])

  useEffect(() => {
    if (selectedSignOnUser && signedOnUsers && filteredSignedOnUsers) {
      if (signedOnUsers.length > 4 && filteredSignedOnUsers.length === 4) {
        let selectedExistsOnFiltered = filteredSignedOnUsers.find((row) => row.staffId === selectedSignOnUser.staffId)
        if (!selectedExistsOnFiltered) {
          let filtered = filteredSignedOnUsers
          filtered[3] = selectedSignOnUser
          setFilteredSignedOnUsers([...filtered])
        }
      }
    }
  }, [selectedSignOnUser, signedOnUsers, filteredSignedOnUsers])

  useEffect(() => {
    if (selectedRewardTransaction && filteredRewardTransaction && rewardTransactionDocketEvents) {
      if (rewardTransactionDocketEvents.length > 4 && filteredRewardTransaction.length === 4) {
        let selectedExistsOnFiltered = filteredRewardTransaction.find((row) => row.id === selectedRewardTransaction.id)
        if (!selectedExistsOnFiltered) {
          let filtered = filteredRewardTransaction
          filtered[3] = selectedRewardTransaction
          setFilteredRewardTransaction([...filtered])
        }
      }
    }
  }, [selectedRewardTransaction, filteredRewardTransaction, rewardTransactionDocketEvents])

  useEffect(() => {
    if (selectedSpendRule && filteredSpendRules && availableSpendRules) {
      if (availableSpendRules.length > 4 && filteredSpendRules.length === 4) {
        let selectedExistsOnFiltered = filteredSpendRules.find(
          (row) => row.timeValueMinutes === selectedSpendRule.timeValueMinutes,
        )
        if (!selectedExistsOnFiltered) {
          let filtered = filteredSpendRules
          filtered[3] = selectedSpendRule
          setFilteredSpendRules([...filtered])
        }
      }
    }
  }, [selectedSpendRule, filteredSpendRules, availableSpendRules])

  useEffect(() => {
    if (partnerId && rewardFlow.selectedStation && rewardFlow.selectedStation.id) {
      let stationId: string = rewardFlow.selectedStation.id
      dispatch(fetchPartnerStaffStationSignOn({ partnerId, stationId }))
    }
  }, [dispatch, partnerId, rewardFlow])

  useEffect(() => {
    if (partnerId) {
      dispatch(fetchPartnerStaffs(partnerId))
    }
  }, [dispatch, partnerId])

  function getClassName(row: RewardTransactionDocketEvent) {
    return row.code[0] === 'B'
      ? classes.transIDBlueButton
      : row.code[0] === 'Y'
      ? classes.transIDYellowButton
      : row.code[0] === 'R'
      ? classes.transIDRedButton
      : row.code[0] === 'G'
      ? classes.transIDGreenButton
      : classes.transIDBlankButton
  }

  const onClickDocket = (event: any, row: RewardTransactionDocketEvent | null) => {
    event.preventDefault()
    setSelectedSpendRule(null)
    setSelectedRewardTransaction(row)
  }

  const onClickSpendRule = (event: any, row: SpendRule) => {
    event.preventDefault()
    setSelectedSpendRule(row)
  }

  const onClickLoadCredit = (event: any) => {
    if (
      selectedSpendRule &&
      selectedSignOnUser &&
      selectedRewardTransaction &&
      partnerId &&
      rewardFlow.selectedStation &&
      rewardFlow.selectedStation.id
    ) {
      setLoading(true)
      setDisplayPinPadModel(false)

      let rewardTransactionRequest: RewardTransactionRequest = {
        docketId: selectedRewardTransaction.id,
        timeValueMinutes: selectedSpendRule.timeValueMinutes,
        issuedByStaffId: selectedSignOnUser.staffId,
        issuedByStaffAuth: userPin,
      }
      axios({
        method: 'POST',
        url: PARTNER_TRANSACTION_API_URL.replace(':partnerId', partnerId).replace(
          ':stationId',
          rewardFlow.selectedStation.id,
        ),
        data: rewardTransactionRequest,
      })
        .then((resp) => {
          setLoading(false)
          setDisplayNotificationModel(true)
          clearDockets()
        })
        .catch((err) => {
          setLoading(false)
        })
    }
  }

  const clearSelection = (event: any) => {
    setUserPin('')
    setSelectedRewardTransaction(null)
    setSelectedSpendRule(null)
    setSelectedSignOnUser(null)
    setAvailableSpendRuled([])
    setFilteredSpendRules([])
  }

  const clearDockets = () => {
    setUserPin('')
    setSelectedRewardTransaction(null)
    setSelectedSpendRule(null)
    setAvailableSpendRuled([])
    setFilteredSpendRules([])
  }

  const onClickDisplayDocketModel = (event: any) => {
    event.preventDefault()
    setDisplayDocketModel(true)
  }

  const onClickDisplayCreditModel = (event: any) => {
    event.preventDefault()
    setDisplayCreditModel(true)
  }

  const onClickDisplayUserModel = (event: any) => {
    event.preventDefault()
    setDisplayUserModel(true)
  }

  const checkPingNeededAndTriggerLoadCredit = (event: any) => {
    event.preventDefault()

    if (rewardFlow.securityType === SecurityType.WITH_PIN) {
      setDisplayPinPadModel(true)
    } else {
      onClickLoadCredit(event)
    }
  }

  const onClickSelectStaff = (event: any, row: PartnerStaffStationSignOn | null) => {
    event.preventDefault()
    if (row) {
      if (row.uuid === selectedSignOnUser?.uuid) {
        setSelectedSignOnUser(null)
      } else {
        setSelectedSignOnUser(row)
      }
    }
  }

  const onClickRefresh = (event: any) => {
    if (sseError) {
      console.log('refreshing')
      setSseCount((prevState) => prevState + 1)
    }
  }

  return (
    <>
      {loading && <Loading />}

      <DocketTransactionModel
        display={displayDocketModel}
        setHide={setDisplayDocketModel}
        rewardTransactionDocketEvent={rewardTransactionDocketEvents}
        getClassName={getClassName}
        setConfirmDocket={setSelectedRewardTransaction}
      />
      <DocketCreditModel
        display={displayCreditModel}
        setHide={setDisplayCreditModel}
        spendRules={availableSpendRules}
        setConfirmDocket={setSelectedSpendRule}
      />

      <DocketNotificationModel
        display={displayNotificationModel}
        setHide={setDisplayNotificationModel}
        message={'Valido Credit Loaded!'}
      />

      <DocketUsersModel
        display={displayUserModel}
        setHide={setDisplayUserModel}
        signOnUsers={signedOnUsers}
        partnerStaffs={partnerStaffs}
        setConfirmDocket={setSelectedSignOnUser}
      />

      <PinPadModel
        userPin={userPin}
        setUserPin={setUserPin}
        display={displayPinPadModel}
        setHide={setDisplayPinPadModel}
        loadCreditWithPin={onClickLoadCredit}
      />

      <div id="wrapper" className="load-credit-screen">
        <div id="header-wrapper" className="landing-page-header">
          <div id="header">
            <div className="home-icon" onClick={() => navigate('/home')}>
              <img src={homeIcon} alt={'user-icon'} />
            </div>
            <div className="users-icon" onClick={() => navigate('/console/staff')}>
              <img src={usersIcon} alt={'user-icon'} />
            </div>
            <div className="header-right">
              <div className="page-title">
                <p>Station</p>
                <h3>{rewardFlow?.selectedStation?.name}</h3>
              </div>
              <div id="valido-logo" onClick={() => navigate('/home')}>
                <span>Valido</span>
              </div>
            </div>
          </div>
        </div>
        <div id="main">
          <div className="col-10">
            <div className="inner-row">
              <div className="grid">
                {Array.of(0, 1, 2, 3).map((rowIndex) => {
                  let staffs: PartnerStaffStationSignOn | null =
                    filteredSignedOnUsers && rowIndex < filteredSignedOnUsers.length
                      ? filteredSignedOnUsers[rowIndex]
                      : null
                  let selectedStaff: PartnerStaff | undefined = partnerStaffs?.find((row) => row.id === staffs?.staffId)

                  return (
                    <div className="staff-button-wrapper">
                      {selectedStaff && (
                        <div
                          className={clsx('profile-button', {
                            [classes.selectedBorder]: selectedSignOnUser?.staffId === selectedStaff.id,
                          })}
                          key={selectedStaff.id}
                          onClick={(e) => onClickSelectStaff(e, staffs)}
                        >
                          <div className="content-center">
                            <img
                              style={{ verticalAlign: 'middle' }}
                              src={'/' + selectedStaff?.avatar}
                              className="load-credit-img"
                              alt={selectedStaff?.firstName}
                            />
                            <span style={{ verticalAlign: 'middle', paddingLeft: 8 }}>
                              {selectedStaff?.firstName}&nbsp;{selectedStaff?.lastName}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  )
                })}
              </div>

              <div className="popup">
                <div className="grid small-grid">
                  <Button
                    className={classes.outlinedLookupButton}
                    variant="outlined"
                    color="secondary"
                    size="large"
                    disableElevation
                    disabled={signedOnUsers && signedOnUsers?.length > 4 ? false : true}
                    onClick={(event) => onClickDisplayUserModel(event)}
                  ></Button>
                </div>
              </div>
            </div>

            <h3 className="text-teal has-icon transaction-id">Transaction ID</h3>

            <div className="inner-row">
              <div className="grid docket-buttons">
                {Array.of(0, 1, 2, 3).map((rowIndex) => {
                  let rewardTx: RewardTransactionDocketEvent | null =
                    rowIndex < filteredRewardTransaction.length ? filteredRewardTransaction[rowIndex] : null
                  return (
                    <div className="docket-button-wrapper">
                      {rewardTx && (
                        <Button
                          className={clsx(rewardTx ? getClassName(rewardTx) : '', {
                            [classes.selectedBorder]: selectedRewardTransaction?.id === rewardTx?.id,
                          })}
                          variant="contained"
                          color="secondary"
                          size="large"
                          disableElevation
                          onClick={(e) => (rewardTx ? onClickDocket(e, rewardTx) : '')}
                        >
                          {rewardTx?.code}
                        </Button>
                      )}
                    </div>
                  )
                })}
              </div>

              <div className="popup">
                <div className="grid small-grid">
                  <Button
                    className={classes.outlinedLookupButton}
                    variant="outlined"
                    color="secondary"
                    size="large"
                    disableElevation
                    disabled={rewardTransactionDocketEvents?.length < 5}
                    onClick={(event) => onClickDisplayDocketModel(event)}
                  ></Button>
                </div>
              </div>
            </div>

            <h3 className="text-teal has-icon parking-credit">Parking Credit</h3>

            <div className="inner-row">
              <div className="grid credit-buttons">
                {Array.of(0, 1, 2, 3).map((rowIndex) => {
                  let spendRule: SpendRule | null =
                    rowIndex < filteredSpendRules.length ? filteredSpendRules[rowIndex] : null

                  return (
                    <div className="credit-button-wrapper">
                      {spendRule && (
                        <Button
                          className={clsx(classes.creditButton, {
                            [classes.selectedBorder]: selectedSpendRule?.timeValueAmount === spendRule.timeValueAmount,
                          })}
                          variant="contained"
                          color="secondary"
                          size="large"
                          disableElevation
                          onClick={(e) => (spendRule ? onClickSpendRule(e, spendRule) : '')}
                        >
                          {format2Decimal(spendRule.minSpend)} - {format2Decimal(spendRule.maxSpend)}
                          <span className="button-subtext">{toDisplayHoursOrMinutes(spendRule.timeValueMinutes)} </span>
                        </Button>
                      )}
                    </div>
                  )
                })}
              </div>

              <div className="popup">
                <div className="grid small-grid">
                  <Button
                    className={classes.outlinedLookupButton}
                    variant="outlined"
                    color="secondary"
                    size="large"
                    disableElevation
                    disabled={availableSpendRules.length < 4}
                    onClick={(event) => onClickDisplayCreditModel(event)}
                  ></Button>
                </div>
              </div>
            </div>

            <Grid container spacing={1} style={{ marginTop: 50 }}>
              <Grid item xs={4} style={{ textAlign: 'right' }}>
                <Button
                  className={classes.outlinedCancelButton}
                  variant="outlined"
                  color="secondary"
                  size="large"
                  disableElevation
                  fullWidth
                  onClick={(event) => clearSelection(event)}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={4} style={{ textAlign: 'right' }}>
                <Button
                  className={classes.outlinedRefreshButton}
                  variant="outlined"
                  color="secondary"
                  size="large"
                  disableElevation
                  fullWidth
                  disabled={sseError === null}
                  onClick={(event) => onClickRefresh(event)}
                >
                  Refresh
                </Button>
              </Grid>

              <Grid item xs={4} style={{ textAlign: 'right' }}>
                <Button
                  className={classes.outlinedCreditButton}
                  variant="outlined"
                  color="secondary"
                  size="large"
                  disableElevation
                  fullWidth
                  disabled={
                    selectedSpendRule === null || selectedRewardTransaction === null || selectedSignOnUser === null
                  }
                  onClick={(event) => checkPingNeededAndTriggerLoadCredit(event)}
                >
                  Load Credit
                </Button>
              </Grid>
            </Grid>
          </div>

          <div className="col-2"></div>
        </div>
        {<pre>{sseError}</pre>}
      </div>
    </>
  )
}
