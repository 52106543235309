import { Lifecycle } from '../../store/reducers/common/types'
import { TableColumn } from '../../components/table/types'

export interface Station {
  uuid: string
  code: string
  clientRef: string
  name: string
  location: string
  qrCode: string
  lifecycle: Lifecycle
  partnerId: string
  version: number
  new: boolean
  id: string
}

export interface StationState {
  success: boolean
  error: string | null
  stations: Station[] | null
  station: Station | null
  currentRequestId: string | undefined
  loading: 'idle' | 'pending'
}

export const PARTNER_STATIONS_API_URL = '/partners/:partnerId/stations'

export const stationListColumn: TableColumn[] = [
  {
    id: 'name',
    label: 'Station Name',
    name: 'Name',
    sortable: true,
    filterable: false,
    minWidth: 400 / 12,
  },
  {
    id: 'location',
    label: 'Location',
    name: 'startDate',
    sortable: true,
    filterable: false,
    minWidth: 400 / 12,
  },
  {
    id: 'qrCode',
    label: 'Station ID',
    name: 'qrCode',
    sortable: true,
    filterable: false,
    minWidth: 200 / 12,
  },
  {
    id: 'status',
    label: 'Status',
    name: 'lifecycle',
    sortable: true,
    filterable: false,
    minWidth: 200 / 12,
  },
]
