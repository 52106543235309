import { AppConfigState } from './types'
import { createSlice } from '@reduxjs/toolkit'

const initialState: AppConfigState = {
  configured: false,
  loading: false,
  error: null,
  appConfig: null,
}

const appConfigSlice = createSlice({
  name: 'appConfigReducer',
  initialState,
  reducers: {
    appConfigRequest: (state) => {
      state.loading = true
      state.error = null
    },
    appConfigResponse: (state, action) => {
      state.loading = false
      state.appConfig = action.payload
      state.configured = true
    },
    appConfigError: (state, action) => {
      state.loading = false
      state.error = action.payload.error
    },
    appConfigReset: (state) => {
      state = initialState
    },
  },
})

export const { appConfigRequest, appConfigResponse, appConfigError, appConfigReset } = appConfigSlice.actions

export default appConfigSlice.reducer
