export const APP_CONFIG = 'config'

export interface AppConfig {
  config: Config
  firebase: FirebaseConfig
  mapConfig: MapBoxConfig
}

export interface Config {
  environment: string
}

export interface FirebaseConfig {
  apiKey: string
  authDomain: string
}

export interface MapBoxConfig {
  token: string
  url: string
}

export interface AppConfigState {
  configured: boolean
  loading: boolean
  error: string | null
  appConfig: AppConfig | null
}
