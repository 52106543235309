import { CampaignState } from './types'
import { createSlice } from '@reduxjs/toolkit'
import { fetchCampaignById, fetchCampaigns } from './thunk'
import { RootState } from '../../store'

const initialState: CampaignState = {
  error: null,
  campaigns: null,
  campaign: null,
  success: false,
  loading: 'idle',
  currentRequestId: undefined,
}

const campaignsSlice = createSlice({
  name: 'campaignsReducer',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchCampaigns.pending.type]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [fetchCampaigns.fulfilled.type]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.campaigns = action.payload
        state.currentRequestId = undefined
      }
    },
    [fetchCampaigns.rejected.type]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.error = action.error
        state.currentRequestId = undefined
      }
    },
    [fetchCampaignById.pending.type]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [fetchCampaignById.fulfilled.type]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.campaign = action.payload
        state.currentRequestId = undefined
      }
    },
    [fetchCampaignById.rejected.type]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.error = action.error
        state.currentRequestId = undefined
      }
    },
  },
})

export const selectCampaigns = (state: RootState) => state.campaignsReducer.campaigns
export const selectCampaignsLoading = (state: RootState) => state.campaignsReducer.loading === 'pending'
export const selectCampaign = (state: RootState) => state.campaignsReducer.campaign
export const selectCampaignLoading = (state: RootState) => state.campaignsReducer.loading === 'pending'

export default campaignsSlice.reducer
