import makeStyles from '@mui/styles/makeStyles'
import React, { Dispatch, SetStateAction } from 'react'
import creditLoaded from '../../../assets/svg/Icon_ValidoTick.svg'
import { ClickAwayListener } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  loadModal: {
    background: '#FCFCFC 0% 0% no-repeat padding-box',
    padding: '0 0px 20px 20px',
    borderRadius: '6px',
    width: '620px',
    height: '137px',
    border: '1px solid #B5B5B5',
    boxShadow: '0px 6px 10px #00000029',
  },

  message: {
    paddingTop: '30px',
    fontSize: 32,
    color: '#05A718',
    letterSpacing: '-0.64px',
  },
  closeIcon: {
    marginTop: 8,
    textAlign: 'right',
    marginRight: 8,
    cursor: 'pointer',
  },
}))

interface IProps {
  display: boolean
  setHide: Dispatch<SetStateAction<boolean>>
  message: string
}

const DocketNotificationModel: React.FC<IProps> = (props) => {
  const { display, setHide, message } = props
  const classes = useStyles()

  const handleClickAway = () => {
    setHide(false)
  }

  return (
    <div className={display ? 'modalOverlayBG' : 'modalOverlayBGHide'}>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className={classes.loadModal}>
          <div className={classes.closeIcon} onClick={() => handleClickAway()}>
            <CloseIcon style={{ color: '#525252' }} fontSize={'small'} />
          </div>
          <div className="valido-credit-loaded-center">
            <img src={creditLoaded} alt={'loaded'} style={{ verticalAlign: 'middle', height: 78, width: 78 }} />
            <span style={{ verticalAlign: 'middle', paddingLeft: 8 }} className={classes.message}>
              {message}
            </span>
          </div>
        </div>
      </ClickAwayListener>
    </div>
  )
}
export default DocketNotificationModel
