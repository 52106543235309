import { Lifecycle } from '../common/types'

export interface Facility {
  uuid: string
  code: string
  clientRef: string
  name: string
  publicId: string
  lifecycle: Lifecycle
  latitude: string
  longitude: string
  mapLabel: string
  timeZoneId: string
  networkProviderId: string
  version: number
  id: string
}

export interface FacilityState {
  success: boolean
  error: string | null
  facilities: Facility[] | null
  facility: Facility | null
  currentRequestId: string | undefined
  loading: 'idle' | 'pending'
}

export const PARTNER_FACILITY_API_URL = '/network-providers/:providerId/facilities'
