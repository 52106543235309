import React from 'react'
import { TextField } from '@mui/material'

export interface IProps {
  name: string
  label: string
  onChange: (event: any) => void
  type?: string
}

const FormTextField: React.FC<IProps> = (props) => {
  const { name, label, onChange, type } = props

  return (
    <TextField
      name={name}
      label={label}
      variant="outlined"
      margin="dense"
      required={false}
      type={type ? type : 'text'}
      fullWidth
      onChange={onChange}
    />
  )
}

export default FormTextField
