import React from 'react'
import { LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'

export default function pickerProviderWrapper(component: any, locale: any) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
      {component}
    </LocalizationProvider>
  )
}
