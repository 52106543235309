import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/database'
import { RootState } from '../../index'

export interface AuthState {
  initialized: boolean
  loggedIn: boolean
  loading: boolean
  user: firebase.User | null
  error: string | null
}

export const selectCurrentFirebaseUser = (state: RootState) =>
  state.authReducer.loggedIn ? state.authReducer.user : null
export const selectIsLoggedOn = (state: RootState) => [state.authReducer.initialized, state.authReducer.loggedIn]
export const isCompletedLogin = (state: RootState) =>
  state.authReducer.loggedIn && state.authReducer.user ? true : false
export const selectAuthError = (state: RootState) => state.authReducer.error
export const selectCurrentFirebaseToken = async (state: RootState) =>
  state.authReducer.loggedIn ? await state.authReducer.user?.getIdToken : null

export const selectPartnerId = (state: RootState) => {
  if (state.authReducer.loggedIn && state.authReducer.user) {
    if (
      state.authReducer.user.uid === '2a3f2a98-7a76-4139-9485-d93c400abe4f' ||
      state.authReducer.user.uid === 'b9ba3baf-1f31-4c0a-801b-62e409cb013b'
    ) {
      return 'b9c574af-5945-4610-bbf4-ee60dfa103ef'
    } else if (
      state.authReducer.user.uid === '7597b069-2bfe-4cd0-b37e-0705d13493ae' ||
      state.authReducer.user.uid === '0d07f3c6-aa98-4c60-9915-6a3bd989b426' ||
      state.authReducer.user.uid === 'c8b8db6f-66b9-47e4-9faa-1c1414a41346'
    ) {
      return '1673cd27-6ac7-481c-a188-6247a5b86f4e'
    } else if (
      state.authReducer.user.uid === '7152212e-c93a-11ec-9d64-0242ac120002' ||
      state.authReducer.user.uid === '71522336-c93a-11ec-9d64-0242ac120002'
    ) {
      return 'e70e98c6-d2ed-4c4c-9e42-5d4deadd5b79'
    } else if (
      state.authReducer.user.uid === '7c865664-c93a-11ec-9d64-0242ac120002' ||
      state.authReducer.user.uid === '7c865bbe-c93a-11ec-9d64-0242ac120002'
    ) {
      return '7b1711a3-7d46-46dc-8dd8-eaa24a4f7f85'
    }
  }
}
