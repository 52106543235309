import React, { useEffect } from 'react'
import './App.css'
import './index.css'
import './version-static-valido-console.css'
import RoutesUtil from './routes-util'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { RootState, useAppDispatch } from './store'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/database'
import setUpAxios from './config/axios/axios'
import { getAppConfigThunk } from './store/reducers/app/thunk'
import { setLoggedOff, setLoggedOn } from './store/reducers/auth/reducers'

interface IProps {
  configCode: string
}
const App: React.FC<IProps> = (props: IProps) => {
  const dispatch = useAppDispatch()
  const { i18n } = useTranslation('', { useSuspense: false })

  useEffect(() => {
    i18n.changeLanguage('en')
  }, [i18n])

  const { appConfig } = useSelector(
    (state: RootState) => ({
      appConfig: state.appConfigReducer.appConfig,
    }),
    shallowEqual,
  )

  useEffect(() => {
    dispatch(getAppConfigThunk(props.configCode))
  }, [dispatch, props.configCode]) // only called once

  useEffect(() => {
    function handleStateChange(user: firebase.User | null) {
      if (user) {
        dispatch(setLoggedOn(user))
      } else {
        dispatch(setLoggedOff())
      }
    }

    if (appConfig != null) {
      console.info('Applying appConfig', appConfig)
      const firebaseConfig = appConfig.firebase
      console.info('Applying firebase config', firebaseConfig)
      firebase.initializeApp(firebaseConfig)
      firebase.auth().onAuthStateChanged((user) => handleStateChange(user))
      setUpAxios()
    }
  }, [dispatch, appConfig])

  console.info('Git sha: ' + process.env.REACT_APP_GIT_SHA)

  return <RoutesUtil />
}

export default App
