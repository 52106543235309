import { Box, Container, Grid, TextField } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import Button from '@mui/material/Button'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import clsx from 'clsx'
import { RewardTransactionDocketEvent } from '../../../store/reducers/rewards-flow/types'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  outlinedButton: {
    fontFamily: 'Nunito Sans',
    fontWeight: 'bold',
    textTransform: 'none',
    fontSize: '0.875rem!important',
    borderRadius: '6px',
    borderWidth: '2px',
    letterSpacing: '0',
    color: '#147AC6!important',
    height: '40px',
    width: '91px',
    borderColor: '#147AC6!important',
  },
  containedButton: {
    fontFamily: 'Nunito Sans',
    fontWeight: 'bold!important',
    textTransform: 'none',
    fontSize: '0.875rem!important',
    borderRadius: '6px',
    borderWidth: '2px',
    letterSpacing: '0',
    color: '#ffffff!important',
    height: '40px',
    borderColor: '#147AC6!important',
    backgroundColor: '#147AC6!important',
    paddingLeft: '40px!important',
    paddingRight: '40px!important',
    marginLeft: '10px!important',
    width: '85px',
  },
  loadModal: {
    background: 'white',
    padding: '0 20px 20px 20px',
    borderRadius: '6px',
    width: '820px',
  },
  selectedBorder: {
    border: '3px solid #FC00FC !important',
  },
}))

interface IProps {
  display: boolean
  setHide: Dispatch<SetStateAction<boolean>>
  rewardTransactionDocketEvent: RewardTransactionDocketEvent[]
  getClassName: (row: RewardTransactionDocketEvent) => string
  setConfirmDocket: Dispatch<SetStateAction<RewardTransactionDocketEvent | null>>
}

const DocketTransactionModel: React.FC<IProps> = (props) => {
  const { display, setHide, rewardTransactionDocketEvent, getClassName, setConfirmDocket } = props
  const classes = useStyles()

  const [selected, setSelected] = useState<RewardTransactionDocketEvent | null>(null)
  const [filteredTransaction, setFilteredTransaction] = useState<RewardTransactionDocketEvent[]>([])

  const [letter1, setLetter1] = useState<string>('')
  const [letter2, setLetter2] = useState<string>('')
  const [letter3, setLetter3] = useState<string>('')

  useEffect(() => {
    if (rewardTransactionDocketEvent) {
      setFilteredTransaction(rewardTransactionDocketEvent)
    }
  }, [rewardTransactionDocketEvent])

  useEffect(() => {
    if ((letter1 || letter2 || letter3) && filteredTransaction) {
      let combined = letter1 + letter2 + letter3
      let filtered = filteredTransaction.filter((row) => row.code.includes(combined))
      setFilteredTransaction([...filtered])
    } else {
      setFilteredTransaction([...rewardTransactionDocketEvent])
    }
  }, [letter1, letter2, letter3, filteredTransaction, rewardTransactionDocketEvent])

  const onClickHideDocketModel = (event: any) => {
    event.preventDefault()
    setHide(false)
    clearModelWhenClosed()
  }

  const clearModelWhenClosed = () => {
    setLetter1('')
    setLetter2('')
    setLetter3('')
  }

  const onClickDocket = (event: any, row: RewardTransactionDocketEvent | null) => {
    event.preventDefault()
    if (row) {
      if (row.id === selected?.id) {
        setSelected(null)
      } else {
        setSelected(row)
      }
    }
  }

  const onClickHConfirmDocket = (event: any) => {
    event.preventDefault()
    if (selected) {
      setConfirmDocket(selected)
      setHide(false)
      clearModelWhenClosed()
    }
  }

  const onChangeText = (event: any) => {
    let { name, value } = event.target
    if (name === 'letter1') {
      setLetter1(value)
    } else if (name === 'letter2') {
      setLetter2(value)
    } else if (name === 'letter3') {
      setLetter3(value)
    }
  }

  return (
    <div className={display ? 'modalOverlayBG' : 'modalOverlayBGHide'}>
      <Box className={classes.loadModal}>
        <Container maxWidth="lg" disableGutters={true} className="form-modal-styles">
          <Grid container spacing={2}>
            <Grid item xs={12} style={{ paddingBottom: '0px' }}>
              <h3 className="text-teal has-icon transaction-id">Transaction ID.</h3>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                paddingBottom: '0px',
                paddingTop: '0px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <p style={{ paddingBottom: '0', marginRight: '40px' }}>Enter Transaction ID. or Select Tile</p>
              <TextField
                inputProps={{ className: 'docketInput' }}
                id="letterId1"
                name="letter1"
                label=""
                value={letter1}
                InputLabelProps={{ className: 'docketLabel' }}
                variant="outlined"
                margin="dense"
                style={{ width: '42px', height: '48px', marginRight: '10px' }}
                onChange={(event) => onChangeText(event)}
              />
              <TextField
                inputProps={{ className: 'docketInput' }}
                id="letterId2"
                name="letter2"
                label=""
                value={letter2}
                InputLabelProps={{ className: 'docketLabel' }}
                variant="outlined"
                margin="dense"
                style={{ width: '42px', height: '48px', marginRight: '10px' }}
                onChange={(event) => onChangeText(event)}
              />
              <TextField
                inputProps={{ className: 'docketInput' }}
                id="letterId3"
                name="letter3"
                label=""
                value={letter3}
                InputLabelProps={{ className: 'docketLabel' }}
                variant="outlined"
                margin="dense"
                style={{ width: '42px', height: '48px', marginRight: '10px' }}
                onChange={(event) => onChangeText(event)}
              />
            </Grid>
            <Grid item xs={12} style={{ paddingBottom: '0px' }}>
              <div className="grid docket-buttons">
                {filteredTransaction.map((rewardTx) => {
                  return (
                    <div className="docket-button-wrapper" onClick={(e) => onClickDocket(e, rewardTx)}>
                      <Button
                        className={clsx(getClassName(rewardTx), {
                          [classes.selectedBorder]: selected?.id === rewardTx.id,
                        })}
                        variant="contained"
                        color="secondary"
                        size="large"
                        disableElevation
                      >
                        {rewardTx?.code}
                      </Button>
                    </div>
                  )
                })}
                <div className="docket-button-wrapper"></div>
              </div>
            </Grid>
            <Grid item xs={12} style={{ paddingBottom: '0px', textAlign: 'right' }}>
              <Button
                className={classes.outlinedButton}
                variant="outlined"
                color="primary"
                size="large"
                disableElevation
                onClick={(event) => onClickHideDocketModel(event)}
              >
                Cancel
              </Button>
              <Button
                className={classes.containedButton}
                variant="contained"
                color="primary"
                size="large"
                disableElevation
                disabled={selected === null}
                onClick={(event) => onClickHConfirmDocket(event)}
              >
                Done
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div>
  )
}

export default DocketTransactionModel
