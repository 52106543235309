import axios from 'axios'
import { PARTNER_FACILITY_API_URL } from './types'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const fetchFacilities = createAsyncThunk(
  'facilities/fetchUsers',
  async (providerId: string, { getState, requestId }) => {
    // @ts-ignore
    const { currentRequestId, loading } = getState().facilityReducer
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }
    const asyncResult = await axios.get('/reward-service' + PARTNER_FACILITY_API_URL.replace(':providerId', providerId))
    return asyncResult.data
  },
)

export const fetchFacilityById = createAsyncThunk(
  'facilities/fetchFacilityById',
  async ({ providerId, facilityId }: { providerId: string; facilityId: string }, { getState, requestId }) => {
    // @ts-ignore
    const { currentRequestId, loading } = getState().facilityReducer
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }
    const asyncResult = await axios.get(
      '/reward-service' + PARTNER_FACILITY_API_URL.replace(':providerId', providerId) + '/' + facilityId,
    )
    return asyncResult.data
  },
)
