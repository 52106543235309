import { Lifecycle } from '../common/types'

export interface Offer {
  id: string
  code: string
  clientRef: string
  name: string
  lifecycle: Lifecycle
  startDate: string
  endDate: string
  startTime: number
  endTime: number
  validMonday: boolean
  validTuesday: boolean
  validWednesday: boolean
  validThursday: boolean
  validFriday: boolean
  validSaturday: boolean
  validSunday: boolean
  timeValueMinutes: number
  timeValueAmount: number
  noOfFacilities: number
  networkProviderId: string
  version: number
  weekDaysSelected: boolean
  allDaySelected: boolean
  weekEndSelected: boolean
  facilityId: string
}

export interface OfferState {
  success: boolean
  error: string | null
  offers: Offer[] | null
  offer: Offer | null
  currentRequestId: string | undefined
  loading: 'idle' | 'pending'
}

export const PROVIDER_OFFER_API_URL = '/network-providers/:providerId/offers'
