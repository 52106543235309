export interface NotificationState {
  type: NotificationType | null
  messages: string[] | null
}

export enum NotificationType {
  ERROR = 'ERROR',
  INFO = 'INFO',
  WARN = 'WARN',
}
