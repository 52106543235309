import React from 'react'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(() => ({
  active: {
    color: '#42A545',
  },
  draft: {
    color: '#1B8BD1',
  },
  suspended: {
    color: '#E3CB13',
  },
}))

interface IProps {
  status: string
}

const StatusCell: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const { status } = props
  const className =
    status === 'Active'
      ? classes.active
      : status === 'Draft'
      ? classes.draft
      : status === 'Suspended'
      ? classes.suspended
      : classes.draft

  return <span className={className}>{status}</span>
}

export default StatusCell
