import * as React from 'react'
import { Dispatch, SetStateAction } from 'react'

interface IProps {
  userPin: string
  setUserPin: Dispatch<SetStateAction<string>>
  disable: boolean
}

const PinPad: React.FC<IProps> = (props) => {
  const { userPin, setUserPin, disable } = props

  const onClickSetUserPin = (value: number) => {
    if (userPin.length !== 4) {
      setUserPin(userPin + value)
    }
  }

  const onClickClearUserPin = () => {
    if (!disable) {
      setUserPin('')
    }
  }

  const onClickDeleteLast = () => {
    if (!disable) {
      setUserPin(userPin.substring(0, userPin.length - 1))
    }
  }

  const displayUserPin = (userPin: string) => {
    if (!disable) {
      return userPin.split('').map((row) => '*')
    }
  }

  return (
    <div className="calculator grid" style={{ gridTemplateColumns: '16px 2fr 16px' }}>
      <div></div>
      <div className="calculator" style={{ height: 350 }}>
        <div className="entry entry-text">{displayUserPin(userPin)}</div>

        <div className={disable ? 'number number-disable' : 'number'} onClick={() => onClickSetUserPin(1)}>
          <span>1</span>
        </div>
        <div className={disable ? 'number number-disable' : 'number'} onClick={() => onClickSetUserPin(2)}>
          <span>2</span>
        </div>
        <div className={disable ? 'number number-disable' : 'number'} onClick={() => onClickSetUserPin(3)}>
          <span>3</span>
        </div>
        <div className={disable ? 'number number-disable' : 'number'} onClick={() => onClickSetUserPin(4)}>
          <span>4</span>
        </div>
        <div className={disable ? 'number number-disable' : 'number'} onClick={() => onClickSetUserPin(5)}>
          <span>5</span>
        </div>
        <div className={disable ? 'number number-disable' : 'number'} onClick={() => onClickSetUserPin(6)}>
          <span>6</span>
        </div>
        <div className={disable ? 'number number-disable' : 'number'} onClick={() => onClickSetUserPin(7)}>
          <span>7</span>
        </div>
        <div className={disable ? 'number number-disable' : 'number'} onClick={() => onClickSetUserPin(8)}>
          <span>8</span>
        </div>
        <div className={disable ? 'number number-disable' : 'number'} onClick={() => onClickSetUserPin(9)}>
          <span>9</span>
        </div>
        <div
          className={disable ? 'number text-button number-disable' : 'number text-button'}
          onClick={() => onClickDeleteLast()}
        >
          <span>Del</span>
        </div>
        <div className={disable ? 'number number-disable' : 'number'} onClick={() => onClickSetUserPin(0)}>
          <span>0</span>
        </div>
        <div
          className={disable ? 'number text-button number-disable' : 'number text-button'}
          onClick={() => onClickClearUserPin()}
        >
          <span>Clear</span>
        </div>
      </div>
    </div>
  )
}

export default PinPad
