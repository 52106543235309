import { IdName, Lifecycle } from '../../store/reducers/common/types'
import { TableColumn } from '../../components/table/types'

export interface Campaign {
  id: string
  code: string
  clientRef: string
  name: string
  startDate: string
  endDate: string
  offerId: string
  lifecycle: Lifecycle
  partnerId: string
  version: number
  new: boolean
  networkProviderId: string
  spendRules: SpendRule[]
}

export interface SpendRule {
  minSpend: number
  maxSpend: number
  timeValueMinutes: number
  timeValueAmount: number
}

export interface CampaignState {
  success: boolean
  error: string | null
  campaigns: Campaign[] | null
  campaign: Campaign | null
  loading: 'idle' | 'pending'
  currentRequestId: string | undefined
}

export const PARTNER_CAMPAIGNS_API_URL = '/partners/:partnerId/campaigns'

export const campaignListColumn: TableColumn[] = [
  {
    id: 'name',
    label: 'Name',
    name: 'Name',
    sortable: true,
    filterable: false,
    minWidth: 600 / 12,
  },
  {
    id: 'startDate',
    label: 'Start Date',
    name: 'startDate',
    sortable: true,
    filterable: false,
    minWidth: 200 / 12,
  },
  {
    id: 'endDate',
    label: 'End Date',
    name: 'endDate',
    sortable: true,
    filterable: false,
    minWidth: 200 / 12,
  },
  {
    id: 'status',
    label: 'Status',
    name: 'lifecycle',
    sortable: true,
    filterable: false,
    minWidth: 200 / 12,
  },
]

export const selectOfferListColumns: TableColumn[] = [
  {
    id: 'networkProvider',
    label: 'Network Provider',
    name: 'Name',
    sortable: false,
    filterable: false,
    minWidth: 200 / 12,
  },
  {
    id: 'providerOffer',
    label: 'Provider Offer',
    name: 'startDate',
    sortable: false,
    filterable: false,
    minWidth: 200 / 12,
  },
  {
    id: 'facilities',
    label: 'Facilities',
    name: 'facilities',
    sortable: false,
    filterable: false,
    minWidth: 200 / 12,
  },
  {
    id: 'validity',
    label: 'Validity',
    name: 'validity',
    sortable: true,
    filterable: false,
    minWidth: 300 / 12,
  },
  {
    id: 'timeValue',
    label: 'Time Value',
    name: 'timeValue',
    sortable: true,
    filterable: false,
    minWidth: 300 / 12,
  },
]

export const providerIdName: IdName = {
  id: 'f7689b9e-b194-4736-85ca-51cd3633c21a',
  name: 'Univarsal Provider',
}

export const providerIdNames: IdName[] = [
  {
    id: 'f7689b9e-b194-4736-85ca-51cd3633c21a',
    name: 'Univarsal Provider',
  },
]
