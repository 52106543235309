import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import SelectStation from './pages/console/select-station'
import Login from './pages/auth/login/login'
import PublicLayout from './layout/public-layout'
import PrivateLayout from './layout/private-layout'
import Home from './pages/home'
import ActivityList from './pages/activities/activity-list'
import UserList from './pages/users/user-list'
import ConsoleStaffPin from './pages/console/console-staff-pin'
import SelectStaff from './pages/console/select-staff'
import CampaignList from './pages/campaigns/campaign-list'
import CampaignForm from './pages/campaigns/campaign-form'
import StationsList from './pages/pos/stations-list'
import StationForm from './pages/pos/stations-form'
import UserForm from './pages/users/user-form'
import SelectDocket from './pages/console/select-docket'

interface IProps {}

const RoutesUtil: React.FC<IProps> = (props) => {
  return (
    <>
      <Router>
        <div>
          <Routes>
            <Route path="/campaigns" element={<PrivateLayout />}>
              <Route index={true} element={<CampaignList />} />
              <Route index={false} path=":campaignId" element={<CampaignForm />} />
              <Route index={false} path="new" element={<CampaignForm />} />
            </Route>

            <Route path="/stations" element={<PrivateLayout />}>
              <Route index={true} element={<StationsList />} />
              <Route index={false} path=":stationId" element={<StationForm />} />
              <Route index={false} path="new" element={<StationForm />} />
            </Route>

            <Route path="/users" element={<PrivateLayout />}>
              <Route index={true} element={<UserList />} />
              <Route index={false} path=":userId" element={<UserForm />} />
              <Route index={false} path="new" element={<UserForm />} />
            </Route>

            <Route path="/login" element={<PublicLayout />}>
              <Route index element={<Login />} />
            </Route>

            <Route path="/activities" element={<PrivateLayout />}>
              <Route index element={<ActivityList />} />
            </Route>

            <Route path="/console/stations" element={<PrivateLayout />}>
              <Route index element={<SelectStation />} />
            </Route>

            <Route path="/console/select-docket" element={<PrivateLayout />}>
              <Route index element={<SelectDocket />} />
            </Route>

            <Route path="/console/staff" element={<PrivateLayout />}>
              <Route index element={<SelectStaff />} />
            </Route>

            <Route path="/console/console-staff" element={<PrivateLayout />}>
              <Route index element={<SelectStaff />} />
            </Route>

            <Route path="/console/staff-pin" element={<PrivateLayout />}>
              <Route index element={<ConsoleStaffPin />} />
            </Route>

            <Route path="/home" element={<PrivateLayout />}>
              <Route index element={<Home />} />
            </Route>

            <Route path="/" element={<PublicLayout />}>
              <Route index element={<Login />} />
            </Route>
          </Routes>
        </div>
      </Router>
    </>
  )
}

export default RoutesUtil
