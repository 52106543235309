import React from 'react'
import { SpendRule } from '../types'
import { format2Decimal, toDisplayHoursOrMinutes } from '../../../util/util'
import { Grid } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

interface IProps {
  spendRule: SpendRule
}

const SpendRuleTile: React.FC<IProps> = (props) => {
  const { spendRule } = props

  return (
    <Grid item xs={2} md={2}>
      <div className="campaign-tile">
        <CloseIcon style={{ color: '#DE0E13', float: 'right', width: 15, height: 15 }} fontSize={'small'} />
        <div className="campaign-tile-center">
          {format2Decimal(spendRule.minSpend)} - {format2Decimal(spendRule.maxSpend)}
          <br />
          <span style={{ paddingLeft: 8 }}>{toDisplayHoursOrMinutes(spendRule.timeValueMinutes)}</span>
        </div>
      </div>
    </Grid>
  )
}
export default SpendRuleTile
