import { TableColumn } from '../../components/table/types'
import { IdName } from '../../store/reducers/common/types'

export interface RewardTransaction {
  id: string
  code: string
  consumerId: string
  claimId: string
  stationId: string
  campaignId: string
  status: RewardTransactionStatus
  timeValueMinutes: number
  issuedByStaffId: string
  partner?: PartnerMarketing
  createdInstant: string
}

export enum RewardTransactionStatus {
  Valid = 'Valid',
  Redeemed = 'Redeemed',
}

export interface PartnerMarketing {
  id: string
  name: string
  address: string
  logoUrl: string
}

export interface TransactionState {
  loading: boolean
  success: boolean
  error: string | null
  transactions: RewardTransaction[] | null
  transaction: RewardTransaction | null
}

export const reportTypeIdNames: IdName[] = [
  {
    id: 'byProvider',
    name: 'Activity By Provider',
  },
  {
    id: 'byCampaign',
    name: 'Activity By Campaign',
  },
  {
    id: 'byStation',
    name: 'Activity By Station',
  },
  {
    id: 'byStaff',
    name: 'Activity By Staff',
  },
]

export const PARTNER_TRANSACTION_API_URL = '/partners/:partnerId/reward-transactions'

export const transationListColumns: TableColumn[] = [
  {
    id: 'sysCode',
    label: 'Transaction',
    name: 'code',
    sortable: true,
    filterable: false,
    minWidth: 200 / 12,
  },
  {
    id: 'date',
    label: 'Date',
    name: 'clientRef',
    sortable: true,
    filterable: false,
    minWidth: 200 / 12,
  },
  {
    id: 'name',
    label: 'Station',
    name: 'Name',
    sortable: true,
    filterable: false,
    minWidth: 200 / 12,
  },
  {
    id: 'staffId',
    label: 'Staff ID',
    name: 'startDate',
    sortable: true,
    filterable: false,
    minWidth: 200 / 12,
  },

  {
    id: 'reward',
    label: 'Spend',
    name: 'qrCode',
    sortable: true,
    filterable: false,
    minWidth: 200 / 12,
  },
  {
    id: 'timeReward',
    label: 'Credit',
    name: 'lifecycle',
    sortable: true,
    filterable: false,
    minWidth: 200 / 12,
  },
]

export const sampleData: RewardTransaction[] = [
  {
    id: '1',
    code: '2044',
    consumerId: 'QS00172',
    claimId: '2044',
    stationId: 'Childrenswear',
    campaignId: '$100-$200',
    status: RewardTransactionStatus.Redeemed,
    timeValueMinutes: 120,
    issuedByStaffId: '00145',
    createdInstant: '10/11/21 15:40',
  },
  {
    id: '2',
    code: '4767',
    consumerId: 'QS00133',
    claimId: '4767',
    stationId: 'Ladies Fashion',
    campaignId: ' $50-$100',
    status: RewardTransactionStatus.Redeemed,
    timeValueMinutes: 30,
    issuedByStaffId: '00177',
    createdInstant: '10/11/21 15:42',
  },
  {
    id: '2',
    code: '2539',
    consumerId: 'QS02041',
    claimId: '4767',
    stationId: 'Ladies Fashion',
    campaignId: ' $100-$200',
    status: RewardTransactionStatus.Redeemed,
    timeValueMinutes: 120,
    issuedByStaffId: '00214',
    createdInstant: '10/11/21 15:44',
  },
  {
    id: '4',
    code: '2539',
    consumerId: 'QS00172',
    claimId: '4124',
    stationId: 'Childrenswear',
    campaignId: ' $100-$200',
    status: RewardTransactionStatus.Redeemed,
    timeValueMinutes: 120,
    issuedByStaffId: '00145',
    createdInstant: '10/11/21 15:50',
  },
  {
    id: '5',
    code: '4127',
    consumerId: 'QS00133',
    claimId: '4767',
    stationId: 'Ladies Fashion',
    campaignId: ' $50-$100',
    status: RewardTransactionStatus.Redeemed,
    timeValueMinutes: 30,
    issuedByStaffId: '00177',
    createdInstant: '10/11/21 15:56',
  },
]
