import React, { useEffect, useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Button from '@mui/material/Button'
import lockedIcon from '../../assets/svg/button-icon-locked.svg'
import unlockedIcon from '../../assets/svg/button-icon-unlocked.svg'
import homeIcon from '../../assets/svg/header-icon-home.svg'
import { RootState, useAppDispatch, useAppSelector } from '../../store'
import { shallowEqual, useSelector } from 'react-redux'
import Loading from '../../components/ui/Loading'
import { NotificationType } from '../../store/reducers/notifications/types'
import { setNotification } from '../../store/reducers/notifications/reducers'
import { RewardFlow, SecurityType } from '../../store/reducers/rewards-flow/types'
import clsx from 'clsx'
import { initFlow } from '../../store/reducers/rewards-flow/reducers'
import { useNavigate } from 'react-router'
import { selectPartnerId } from '../../store/reducers/auth/types'
import { Station } from '../pos/types'
import { selectStations, selectStationsLoading } from '../pos/reducers'
import { fetchStations } from '../pos/thunk'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  containedButton: {
    fontFamily: 'Nunito Sans',
    fontWeight: 'bold!important',
    textTransform: 'none',
    fontSize: '0.875rem!important',
    borderRadius: '6px',
    borderWidth: '2px',
    letterSpacing: '0',
    color: '#ffffff!important',
    height: '55px',
    borderColor: '#147AC6!important',
    backgroundColor: '#147AC6!important',
    paddingLeft: '40px!important',
    paddingRight: '40px!important',
    marginLeft: '10px!important',
  },
  outlinedButton: {
    fontFamily: 'Nunito Sans',
    fontWeight: 'bold',
    textTransform: 'none',
    fontSize: '0.875rem!important',
    borderRadius: '6px',
    borderWidth: '2px',
    letterSpacing: '0',
    color: '#147AC6!important',
    height: '55px',
    width: '130px',
    borderColor: '#147AC6!important',
  },
  withPinButton: {
    backgroundImage: `url(${lockedIcon})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '30px center',
    backgroundSize: '31px',
    fontSize: '1.25rem!important',
    fontWeight: '800!important',
    textIndent: '50px',
    backgroundColor: '#34AEBE!important',
  },

  selectedBorder: {
    border: '2px solid #FC00FC',
  },

  noPinButton: {
    backgroundImage: `url(${unlockedIcon})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '30px center',
    backgroundSize: '31px',
    fontSize: '1.25rem!important',
    fontWeight: '800!important',
    textIndent: '30px',
    backgroundColor: '#34AEBE!important',
  },
  stationButton: {
    fontSize: '1.25rem!important',
    fontWeight: '800!important',
    backgroundColor: '#E3C02E!important',
  },
  gridNoTopMargin: {
    paddingBottom: '0 !important',
  },
  gridNoMargin: {
    paddingTop: '0 !important',
    paddingBottom: '0 !important',
  },
  slideWrapper: {
    backgroundColor: '#eeeeee',
    paddingLeft: '5% !important',
    paddingRight: '5% !important',
    paddingTop: '10px',
    marginTop: '0px',
    textAlign: 'center',
  },
}))

interface IProps {}

const SelectStation: React.FC<IProps> = (props) => {
  const classes = useStyles()

  const dispatch = useAppDispatch()
  const partnerId = useSelector(selectPartnerId, shallowEqual)
  const navigate = useNavigate()

  const [selectedSecurityType, setSelectedSecurityType] = useState<SecurityType | null>(null)
  const [selectedStation, setSelectedStation] = useState<Station | null>(null)

  const stations = useAppSelector(selectStations, shallowEqual)
  const loading = useAppSelector(selectStationsLoading, shallowEqual)

  const { error, rewardFlow } = useSelector(
    (state: RootState) => ({
      error: state.stationsReducer.error,
      rewardFlow: state.rewardsFlowReducer.rewardFlow,
    }),
    shallowEqual,
  )

  useEffect(() => {
    if (partnerId) {
      dispatch(fetchStations(partnerId))
    }
  }, [dispatch, partnerId])

  useEffect(() => {
    if (rewardFlow) {
      setSelectedStation(rewardFlow.selectedStation)
      setSelectedSecurityType(rewardFlow.securityType)
    }
  }, [rewardFlow])

  const onClickPin = (type: SecurityType) => {
    if (type === selectedSecurityType) {
      setSelectedSecurityType(null)
    } else {
      setSelectedSecurityType(type)
    }
  }

  const onClickStation = (row: Station) => {
    if (row.id === selectedStation?.id) {
      setSelectedStation(null)
    } else {
      setSelectedStation(row)
    }
  }

  useEffect(() => {
    if (error) {
      dispatch(
        setNotification({ type: NotificationType.ERROR, messages: ['Error while loading stations, Please try again'] }),
      )
    }
  }, [error, dispatch])

  const onClickLaunch = () => {
    if (selectedStation && selectedSecurityType) {
      let rewardFlow: RewardFlow = {
        selectedStation: selectedStation,
        securityType: selectedSecurityType,
        signedOnUser: null,
      }
      dispatch(initFlow(rewardFlow))
      navigate('/console/staff')
    }
  }

  const onClickBack = () => {
    navigate('/home')
  }

  return (
    <>
      <div id="wrapper" className="stations-screen">
        {loading && <Loading />}

        <div id="header-wrapper" className="landing-page-header">
          <div id="header">
            <div className="home-icon" onClick={() => navigate('/home')}>
              <img src={homeIcon} alt={'user-icon'} />
            </div>
            <div className="header-right">
              {rewardFlow?.selectedStation?.name && (
                <div className="page-title">
                  <p>Station</p>
                  <h3>{rewardFlow?.selectedStation?.name}</h3>
                </div>
              )}
              <div id="valido-logo" onClick={() => navigate('/home')}>
                <span>Valido</span>
              </div>
            </div>
          </div>
        </div>
        <div id="main">
          <div className="col-10">
            <h1 className="text-blue has-icon padding-small stations" style={{ marginTop: 10 }}>
              Stations
            </h1>

            <h3 className="text-teal has-icon set-station">Set Station Security</h3>

            <div className="grid pin-buttons">
              <Button
                className={clsx(classes.withPinButton, {
                  [classes.selectedBorder]: selectedSecurityType === SecurityType.WITH_PIN,
                })}
                variant="contained"
                color="secondary"
                size="large"
                disableElevation
                onClick={() => onClickPin(SecurityType.WITH_PIN)}
              >
                With Pin
              </Button>
              <Button
                className={clsx(classes.noPinButton, {
                  [classes.selectedBorder]: selectedSecurityType === SecurityType.NO_PIN,
                })}
                variant="contained"
                color="secondary"
                size="large"
                disableElevation
                onClick={() => onClickPin(SecurityType.NO_PIN)}
              >
                No Pin
              </Button>
            </div>

            <h3 className="text-teal has-icon select-station">Select Station</h3>

            <div className="grid station-buttons">
              {stations?.map((row) => (
                <Button
                  className={clsx(classes.stationButton, {
                    [classes.selectedBorder]: selectedStation?.id === row.id,
                  })}
                  variant="contained"
                  color="secondary"
                  size="large"
                  disableElevation
                  onClick={() => onClickStation(row)}
                >
                  {row.name}
                </Button>
              ))}
            </div>

            {/*<pre>{JSON.stringify(stations, null, 2)}</pre>*/}

            <div className="button-row" style={{ flexDirection: 'row', marginTop: 50 }}>
              <div style={{ width: '50%' }}>
                <Button
                  className={classes.outlinedButton}
                  variant="outlined"
                  color="secondary"
                  size="large"
                  disableElevation
                  onClick={() => onClickBack()}
                >
                  Back
                </Button>
              </div>
              <div style={{ width: '50%' }}>
                <Button
                  className={classes.containedButton}
                  variant="contained"
                  color="secondary"
                  size="large"
                  disableElevation
                  disabled={selectedStation === null}
                >
                  Edit Station
                </Button>
                <Button
                  className={classes.containedButton}
                  variant="contained"
                  color="secondary"
                  size="large"
                  disableElevation
                  disabled={selectedStation === null || selectedSecurityType === null}
                  onClick={() => onClickLaunch()}
                >
                  Launch
                </Button>
              </div>
            </div>
          </div>

          <div className="col-2"></div>
        </div>
      </div>
    </>
  )
}

export default SelectStation
