import { UserState } from './types'
import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../store'
import { fetchUserById, fetchUsers } from './thunk'

const initialState: UserState = {
  success: false,
  error: null,
  users: null,
  user: null,
  loading: 'idle',
  currentRequestId: undefined,
}

const usersSlice = createSlice({
  name: 'usersReducer',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchUsers.pending.type]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [fetchUsers.fulfilled.type]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.users = action.payload
        state.currentRequestId = undefined
      }
    },
    [fetchUsers.rejected.type]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.error = action.error
        state.currentRequestId = undefined
      }
    },
    [fetchUserById.pending.type]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [fetchUserById.fulfilled.type]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.user = action.payload
        state.currentRequestId = undefined
      }
    },
    [fetchUserById.rejected.type]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.error = action.error
        state.currentRequestId = undefined
      }
    },
  },
})

export const selectUsers = (state: RootState) => state.usersReducer.users
export const selectUsersLoading = (state: RootState) => state.usersReducer.loading === 'pending'
export const selectUser = (state: RootState) => state.usersReducer.user
export const selectUserLoading = (state: RootState) => state.usersReducer.loading === 'pending'

export default usersSlice.reducer
