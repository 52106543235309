import axios from 'axios'
import { PARTNER_STAFF_API_URL } from './types'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const fetchPartnerStaffs = createAsyncThunk(
  'partnerStaff/fetchPartnerStaffs',
  async (partnerId: string, { getState, requestId }) => {
    // @ts-ignore
    const { currentRequestId, loading } = getState().partnerStaffReducer
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }
    const asyncResult = await axios.get('/reward-service' + PARTNER_STAFF_API_URL.replace(':partnerId', partnerId))
    return asyncResult.data
  },
)
