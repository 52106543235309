import { createSlice } from '@reduxjs/toolkit'
import { PartnerStaffState } from './types'
import { fetchPartnerStaffs } from './thunk'
import { RootState } from '../../index'

const initialState: PartnerStaffState = {
  success: false,
  error: null,
  partnerStaffs: null,
  currentRequestId: undefined,
  loading: 'idle',
}

const partnerStaffSlice = createSlice({
  name: 'partnerStaffReducer',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchPartnerStaffs.pending.type]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [fetchPartnerStaffs.fulfilled.type]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.partnerStaffs = action.payload
        state.currentRequestId = undefined
      }
    },
    [fetchPartnerStaffs.rejected.type]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.error = action.error
        state.currentRequestId = undefined
      }
    },
  },
})

export const selectPartnerStaffs = (state: RootState) => state.partnerStaffReducer.partnerStaffs
export const selectPartnerStaffsLoading = (state: RootState) => state.partnerStaffReducer.loading === 'pending'

export default partnerStaffSlice.reducer
