import React from 'react'
import { ThemeProvider } from '@mui/material/styles'
import './index.css'
import './version-static-valido-console.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { theme } from './theme'
import { Provider } from 'react-redux'
import { StyledEngineProvider } from '@mui/material'
import { APP_CONFIG } from './store/reducers/app/types'
import { createRoot } from 'react-dom/client'
import store from './store'
import './i18n'

const container = document.getElementById('root')!

const root = createRoot(container)
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <App configCode={APP_CONFIG} />
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>
  </React.StrictMode>,
)
reportWebVitals()
