import React, { useEffect, useState } from 'react'
import homeIcon from '../../assets/svg/header-icon-home.svg'
import { useParams } from 'react-router-dom'
import { RootState, useAppDispatch, useAppSelector } from '../../store'
import { shallowEqual, useSelector } from 'react-redux'
import { selectPartnerId } from '../../store/reducers/auth/types'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { useNavigate } from 'react-router'
import { Grid, Slider, Table, TableBody, TableCell, TableRow } from '@mui/material'
import { TextField } from '../../components/final-form/TextField'
import FormEditToolBar from '../../components/nav/FormEditToolBar'
import { Form } from 'react-final-form'
import { IdName, Lifecycle, LifecycleOnly } from '../../store/reducers/common/types'
import { Campaign, providerIdName, providerIdNames, selectOfferListColumns } from './types'
import { DatePicker } from '../../components/final-form/DatePicker'
import CustomTableHead from '../../components/table/CustomTableHead'
import usePagination from '../../hook/usePagination'
import SpendRuleTile from './component/spend-rules-tile'
import { fetchOffers } from '../../store/reducers/offers/thunk'
import { fetchFacilities } from '../../store/reducers/facilities/thunk'
import { Select } from '../../components/final-form/Select'
import { TextField as MuiTextField } from '@mui/material'
import Loading from '../../components/ui/Loading'
import { displayDayString, format2Decimal, toDisplayHoursOrMinutes } from '../../util/util'
import IdNameCell from '../../components/ui/IdNameCell'
import { fetchCampaignById } from './thunk'
import { selectCampaign, selectCampaignLoading } from './reducers'

const marks = [
  {
    value: 0,
    label: '0',
  },

  {
    value: 15,
    label: '15min',
  },
  {
    value: 30,
    label: '30min',
  },
  {
    value: 45,
    label: '45min',
  },
  {
    value: 60,
    label: '1hr',
  },
  {
    value: 120,
    label: '2hr',
  },
  {
    value: 240,
    label: '4hr',
  },
  {
    value: 1440,
    label: 'All Day',
  },
]

interface IProps {}

const CampaignForm: React.FC<IProps> = (props) => {
  const dispatch = useAppDispatch()
  const partnerId = useSelector(selectPartnerId)
  const navigate = useNavigate()
  let { campaignId } = useParams()

  const [initialValue, setInitialValue] = useState<Campaign>()
  const [displayOfferForm, setDisplayOfferForm] = useState<boolean>(false)
  const [sortDirection, sortBy, handleRequestSort] = usePagination()
  const [facilityIdNames, setFacilityIdNames] = useState<IdName[]>([])
  const [offerIdNames, setOfferIdNames] = useState<IdName[]>([])

  const campaign = useAppSelector(selectCampaign, shallowEqual)
  const loading = useAppSelector(selectCampaignLoading, shallowEqual)

  const { offers, facilities } = useSelector(
    (state: RootState) => ({
      error: state.campaignsReducer.error,
      offers: state.offerReducer.offers,
      facilities: state.facilityReducer.facilities,
    }),
    shallowEqual,
  )

  useEffect(() => {
    if (partnerId && campaignId) {
      dispatch(fetchCampaignById({ partnerId, campaignId }))
    }
  }, [dispatch, partnerId, campaignId])

  useEffect(() => {
    dispatch(fetchOffers(providerIdName.id))
    dispatch(fetchFacilities(providerIdName.id))
  }, [dispatch])

  useEffect(() => {
    if (campaign) {
      setInitialValue(campaign)
    }
  }, [campaign])

  useEffect(() => {
    let facilityIdNames: IdName[] = []
    if (facilities) {
      facilities.map((row) => {
        return facilityIdNames.push({
          id: row.id,
          name: row.name,
        })
      })
    }
    setFacilityIdNames(facilityIdNames)
  }, [facilities])

  useEffect(() => {
    let offerIdNames: IdName[] = []
    if (offers) {
      offers.map((row) => {
        return offerIdNames.push({
          id: row.id,
          name: row.name,
        })
      })
    }
    setOfferIdNames(offerIdNames)
  }, [offers])

  const onSubmit = (values: Campaign) => {
    console.log(values)
  }

  const onUpdateLifeCycle = (lifeCycle: Lifecycle) => {
    let lifecycleOnly: LifecycleOnly = { lifecycle: lifeCycle }
    console.log(lifecycleOnly)
  }

  const onClickCancel = () => {
    navigate('/campaigns')
  }

  const handleBackClick = () => {
    navigate('/campaigns')
  }

  const onClickLinkOffer = () => {
    setDisplayOfferForm(true)
  }

  const getNetworkProviderName = (networkProviderId: string) => {
    if (networkProviderId) {
      return providerIdName.name
    }
  }

  const getNetworkOfferName = (offerId: string) => {
    if (offerId && offers) {
      let offerName = offers?.find((row) => row.id === offerId)?.name
      if (offerName) {
        return offerName
      } else {
        return ''
      }
    }
  }

  return (
    <div id="wrapper" className="stations-screen">
      {loading && <Loading />}
      <div id="header-wrapper" className="landing-page-header">
        <div id="header">
          <div className="home-icon" onClick={() => navigate('/home')}>
            <img src={homeIcon} alt={'user-icon'} />
          </div>
          <div className="nav-icons">
            <ArrowBackIosIcon fontSize={'large'} style={{ marginRight: 20 }} onClick={() => handleBackClick()} />
          </div>
          <div className="nav-text campaign-avatar">Campaigns</div>
          <div className="header-right">
            <div id="valido-logo" onClick={() => navigate('/home')}>
              <span>Valido</span>
            </div>
          </div>
        </div>
      </div>

      <Form<Campaign>
        onSubmit={onSubmit}
        initialValues={initialValue}
        render={({ handleSubmit, form, initialValues, submitting, pristine, values }) => {
          return (
            <form onSubmit={handleSubmit} id="editForm" noValidate>
              <div id="main-no-grid">
                <div className="col-header">
                  {!displayOfferForm && (
                    <>
                      <Grid container spacing={1}>
                        <Grid item xs={12} md={12} className="text-heading-pink" style={{ paddingBottom: 8 }}>
                          Campaign
                        </Grid>

                        <Grid item xs={2} md={2}>
                          <TextField name="code" label="Sys Code" variant="outlined" type="text" disabled id="code" />
                        </Grid>
                        <Grid item xs={2} md={2}>
                          <TextField
                            name="clientRef"
                            label="Client Ref"
                            variant="outlined"
                            required={true}
                            type="text"
                          />
                        </Grid>
                        <Grid item xs={4} md={4}>
                          <TextField name="name" label="Campaign Name" variant="outlined" required={true} type="text" />
                        </Grid>

                        <Grid item xs={2} md={2}>
                          <DatePicker name="startDate" format="dd/MM/yyyy" required={false} label="Start Date" />
                        </Grid>

                        <Grid item xs={2} md={2}>
                          <DatePicker name="endDate" format="dd/MM/yyyy" required={false} label="End Date" />
                        </Grid>
                      </Grid>
                      <Grid container spacing={1}>
                        <Grid item xs={9} md={9} className="text-heading-pink" style={{ paddingBottom: 16 }}>
                          Provider Offer
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          md={3}
                          className="text-heading-pink"
                          style={{
                            paddingBottom: 0,
                            fontSize: 16,
                            letterSpacing: -0.32,
                            color: '#147AC6',
                            textAlign: 'right',
                          }}
                        >
                          <div onClick={() => onClickLinkOffer()}>Link Offer</div>
                        </Grid>

                        <Grid item xs={4} md={4}>
                          <MuiTextField
                            name="networkProvider"
                            label="Network Provider"
                            variant="outlined"
                            value={getNetworkProviderName(values.networkProviderId)}
                            type="text"
                            disabled
                            id="networkProvider"
                            fullWidth
                            InputLabelProps={
                              values.networkProviderId
                                ? {
                                    shrink: true,
                                  }
                                : {
                                    shrink: false,
                                  }
                            }
                          />
                        </Grid>
                        <Grid item xs={2} md={2}>
                          <MuiTextField
                            name="providerOffer"
                            label="Provider Offer"
                            variant="outlined"
                            value={getNetworkOfferName(values.offerId)}
                            type="text"
                            disabled
                            id="providerOffer"
                            fullWidth
                            InputLabelProps={
                              values.offerId
                                ? {
                                    shrink: true,
                                  }
                                : {
                                    shrink: false,
                                  }
                            }
                          />
                        </Grid>
                        <Grid item xs={2} md={2}>
                          <MuiTextField
                            name="validDays"
                            label="Valid Days"
                            variant="outlined"
                            type="text"
                            disabled
                            id="validDays"
                          />
                        </Grid>
                        <Grid item xs={2} md={2}>
                          <MuiTextField
                            name="validTimes"
                            label="Valid Times"
                            variant="outlined"
                            type="text"
                            disabled
                            id="validTimes"
                          />
                        </Grid>
                        <Grid item xs={2} md={2}>
                          <MuiTextField
                            name="timeValue"
                            label="Time - Value"
                            variant="outlined"
                            type="text"
                            disabled
                            id="validTimes"
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={1}>
                        <Grid item xs={9} md={9} className="text-heading-pink" style={{ paddingBottom: 0 }}>
                          Spend Rules
                        </Grid>

                        <Grid item xs={9} md={9} style={{ marginTop: 18, paddingRight: 10 }}>
                          <Slider
                            defaultValue={15}
                            step={15}
                            valueLabelDisplay="on"
                            marks={marks}
                            max={240}
                            color={'primary'}
                          />
                        </Grid>
                        <Grid item xs={2} md={2}>
                          <TextField
                            name="minSpend"
                            label="Min Spend $"
                            variant="outlined"
                            type="text"
                            disabled
                            id="minSpend"
                          />
                        </Grid>

                        <Grid item xs={1} md={1}>
                          <TextField
                            name="maxSpend"
                            label="Max Spend $"
                            variant="outlined"
                            type="text"
                            disabled
                            id="maxSpend"
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={1}>
                        {values?.spendRules?.map((spendTile, index) => (
                          <SpendRuleTile spendRule={spendTile} />
                        ))}
                      </Grid>
                    </>
                  )}
                  {displayOfferForm && (
                    <>
                      <Grid container spacing={1} style={{ paddingBottom: 8 }}>
                        <Grid item xs={12} md={12} className="text-heading-pink" style={{ paddingBottom: 0 }}>
                          Select Offer
                        </Grid>
                        <Grid item xs={2} md={2}>
                          <Select
                            name="networkProviderId"
                            label="Network Provider"
                            variant="outlined"
                            required={false}
                            data={providerIdNames}
                            id="networkProviderId"
                          />
                        </Grid>
                        <Grid item xs={2} md={2}>
                          <Select
                            name="offerId"
                            label="Provider Offer"
                            variant="outlined"
                            required={false}
                            data={offerIdNames}
                            id="offerId"
                          />
                        </Grid>
                        <Grid item xs={2} md={2}>
                          <Select
                            name="facilityId"
                            label="Facility"
                            variant="outlined"
                            required={false}
                            data={facilityIdNames}
                            id="facilityId"
                          />
                        </Grid>
                      </Grid>
                      <Table aria-label="tableTitle" size="medium">
                        <CustomTableHead
                          onRequestSort={handleRequestSort}
                          sortDirection={sortDirection}
                          sortBy={sortBy}
                          columns={selectOfferListColumns}
                        />
                        {offers && offers.length > 0 ? (
                          offers.map((row) => (
                            <TableRow key={row.id} data-id={row.id} tabIndex={0} role="button" hover>
                              <TableCell>
                                <IdNameCell idNames={providerIdNames} id={row.networkProviderId} />{' '}
                              </TableCell>
                              <TableCell>{row.name}</TableCell>
                              <TableCell>
                                <IdNameCell idNames={facilityIdNames} id={row.facilityId} />{' '}
                              </TableCell>

                              <TableCell>{displayDayString(row)}</TableCell>
                              <TableCell>
                                {toDisplayHoursOrMinutes(row.timeValueMinutes)} = {format2Decimal(row.timeValueAmount)}
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={6}>No Record found</TableCell>
                          </TableRow>
                        )}
                        <TableBody>
                          <TableRow> </TableRow>
                        </TableBody>
                      </Table>
                    </>
                  )}
                </div>
              </div>
              <FormEditToolBar
                onUpdateLifeCycle={onUpdateLifeCycle}
                onClickCancel={onClickCancel}
                handleSubmit={handleSubmit}
                submitting={submitting}
                pristine={pristine}
                defaultLifeCycle={initialValues?.lifecycle}
              />
            </form>
          )
        }}
      />
    </div>
  )
}

export default CampaignForm
