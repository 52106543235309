import axios from 'axios'
import {
  PARTNER_STAFF_GET_SIGN_ON_API_URL,
  PARTNER_STAFF_POST_SIGN_OFF_API_URL,
  PARTNER_STAFF_POST_SIGN_ON_API_URL,
} from './types'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const fetchPartnerStaffStationSignOn = createAsyncThunk(
  'partnerStaffSignOn/fetchPartnerStaffStationSignOn',
  async ({ partnerId, stationId }: { partnerId: string; stationId: string }, { getState, requestId }) => {
    // @ts-ignore
    const { currentRequestId, loading } = getState().partnerStaffStationSignOnReducer
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }
    const asyncResult = await axios.get(
      '/reward-service' +
        PARTNER_STAFF_GET_SIGN_ON_API_URL.replace(':partnerId', partnerId).replace(':stationId', stationId),
    )
    return asyncResult.data
  },
)

export const postPartnerStaffStationSignOn = createAsyncThunk(
  'partnerStaffSignOn/postPartnerStaffStationSignOn',
  async (
    { partnerId, stationId, staffId }: { partnerId: string; stationId: string; staffId: string },
    { getState, requestId },
  ) => {
    // @ts-ignore
    const { currentRequestId, loading } = getState().partnerStaffStationSignOnReducer
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }
    const asyncResult = await axios.post(
      '/reward-service' +
        PARTNER_STAFF_POST_SIGN_ON_API_URL.replace(':partnerId', partnerId)
          .replace(':stationId', stationId)
          .replace(':staffId', staffId),
      {},
    )
    return asyncResult.data
  },
)

export const postPartnerStaffStationSignOff = createAsyncThunk(
  'partnerStaffSignOn/postPartnerStaffStationSignOff',
  async (
    { partnerId, stationId, staffId }: { partnerId: string; stationId: string; staffId: string },
    { getState, requestId },
  ) => {
    // @ts-ignore
    const { currentRequestId, loading } = getState().partnerStaffStationSignOnReducer
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }
    const asyncResult = await axios.post(
      '/reward-service' +
        PARTNER_STAFF_POST_SIGN_OFF_API_URL.replace(':partnerId', partnerId)
          .replace(':stationId', stationId)
          .replace(':staffId', staffId),
      {},
    )
    return asyncResult.data
  },
)
