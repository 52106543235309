import { Box, Container, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import Button from '@mui/material/Button'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import clsx from 'clsx'
import creditBGIdle from '../../../assets/svg/button-credit-bg-idle.svg'
import creditBGActive from '../../../assets/svg/button-credit-bg-active.svg'
import { PartnerStaffStationSignOn } from '../../../store/reducers/station-staff/types'
import { PartnerStaff } from '../../../store/reducers/partner-staffs/types'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  outlinedButton: {
    fontFamily: 'Nunito Sans',
    fontWeight: 'bold',
    textTransform: 'none',
    fontSize: '0.875rem!important',
    borderRadius: '6px',
    borderWidth: '2px',
    letterSpacing: '0',
    color: '#147AC6!important',
    height: '40px',
    width: '91px',
    borderColor: '#147AC6!important',
  },
  containedButton: {
    fontFamily: 'Nunito Sans',
    fontWeight: 'bold!important',
    textTransform: 'none',
    fontSize: '0.875rem!important',
    borderRadius: '6px',
    borderWidth: '2px',
    letterSpacing: '0',
    color: '#ffffff!important',
    height: '40px',
    borderColor: '#147AC6!important',
    backgroundColor: '#147AC6!important',
    paddingLeft: '40px!important',
    paddingRight: '40px!important',
    marginLeft: '10px!important',
    width: '85px',
  },
  loadModal: {
    background: 'white',
    padding: '0 20px 20px 20px',
    borderRadius: '6px',
    width: '820px',
  },
  selectedBorder: {
    border: '3px solid #FC00FC !important',
  },
  creditButton: {
    backgroundImage: `url(${creditBGIdle})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: '97%',
    fontSize: '1.25rem!important',
    fontWeight: '800!important',
    backgroundColor: '#FAFBFB!important',
    border: '2px solid #FAFBFB!important',
    alignItems: 'flex-start!important',
    borderRadius: '12px!important',
    '&:hover': {
      border: '2px solid #ff00fc!important',
      backgroundImage: `url(${creditBGActive})`,
    },
  },
}))

interface IProps {
  display: boolean
  setHide: Dispatch<SetStateAction<boolean>>
  signOnUsers: PartnerStaffStationSignOn[] | null
  partnerStaffs: PartnerStaff[] | null
  setConfirmDocket: Dispatch<SetStateAction<PartnerStaffStationSignOn | null>>
}

const DocketCreditModel: React.FC<IProps> = (props) => {
  const { display, setHide, signOnUsers, setConfirmDocket, partnerStaffs } = props
  const classes = useStyles()
  const [selected, setSelected] = useState<PartnerStaffStationSignOn | null>(null)
  const [filteredSignedOnUser, setFilteredSignedOnUser] = useState<PartnerStaffStationSignOn[]>([])

  useEffect(() => {
    if (signOnUsers) {
      setFilteredSignedOnUser(signOnUsers)
    }
  }, [signOnUsers])

  const onClickHideDocketModel = (event: any) => {
    event.preventDefault()
    setHide(false)
  }

  const onClickHConfirmDocket = (event: any) => {
    event.preventDefault()
    if (selected) {
      setConfirmDocket(selected)
      setHide(false)
    }
  }

  const onClickDocket = (event: any, row: PartnerStaffStationSignOn) => {
    event.preventDefault()
    if (row.uuid === selected?.uuid) {
      setSelected(null)
    } else {
      setSelected(row)
    }
  }

  return (
    <div className={display ? 'modalOverlayBG' : 'modalOverlayBGHide'}>
      <Box className={classes.loadModal}>
        <Container maxWidth="lg" disableGutters={true} className="form-modal-styles">
          <Grid container spacing={2}>
            <Grid item xs={12} style={{ paddingBottom: '0px' }}>
              <h1 className="text-blue has-icon padding-small stations">Staff</h1>
            </Grid>
            <Grid item xs={12} style={{ paddingBottom: '0px' }}>
              <div className="grid docket-buttons">
                {filteredSignedOnUser.map((partnerStaff) => {
                  let selectedStaff: PartnerStaff | undefined = partnerStaffs?.find(
                    (row) => row.id === partnerStaff?.staffId,
                  )
                  return (
                    <div className="staff-button-wrapper">
                      {selectedStaff && (
                        <div
                          className={clsx('profile-button', {
                            [classes.selectedBorder]: selected && selected.staffId === selectedStaff.id ? true : false,
                          })}
                          key={selectedStaff.id}
                          onClick={(e) => onClickDocket(e, partnerStaff)}
                        >
                          <div className="content-center">
                            <img
                              style={{ verticalAlign: 'middle' }}
                              src={'/' + selectedStaff?.avatar}
                              className="load-credit-img"
                              alt={selectedStaff?.firstName}
                            />
                            <span style={{ verticalAlign: 'middle', paddingLeft: 8 }}>
                              {selectedStaff?.firstName}&nbsp;{selectedStaff?.lastName}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  )
                })}
                <div className="docket-button-wrapper"></div>
              </div>
            </Grid>
            <Grid item xs={12} style={{ paddingBottom: '0px', textAlign: 'right' }}>
              <Button
                className={classes.outlinedButton}
                variant="outlined"
                color="primary"
                size="large"
                disableElevation
                onClick={(event) => onClickHideDocketModel(event)}
              >
                Cancel
              </Button>
              <Button
                className={classes.containedButton}
                variant="contained"
                color="primary"
                size="large"
                disableElevation
                disabled={selected === null}
                onClick={(event) => onClickHConfirmDocket(event)}
              >
                Done
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div>
  )
}

export default DocketCreditModel
