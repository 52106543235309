import React from 'react'
import { Order } from '../components/table/types'

const usePagination = (noOfRowsPerPage?: number) => {
  //const [page, setPage] = React.useState(0)
  //const [rowsPerPage, setRowsPerPage] = React.useState(noOfRowsPerPage ? noOfRowsPerPage : 10)
  const [sortDirection, setSortDirection] = React.useState<Order>('asc')
  const [sortBy, setSortBy] = React.useState<string>('code')

  /*const handleChangePage = (event: unknown, newPage: number) => {
    //console.debug(page, rowsPerPage)
    //setPage(newPage)
  }

  /!*const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }*!/*/

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string | null | undefined) => {
    if (property) {
      const isAsc = sortBy === property && sortDirection === 'asc'
      setSortDirection(isAsc ? 'desc' : 'asc')
      setSortBy(property)
    }
  }

  return [sortDirection, sortBy, handleRequestSort] as const
}
export default usePagination
