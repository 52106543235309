import React, { useEffect, useState } from 'react'
import { Box, Button, Checkbox, Container, FormControlLabel, FormGroup, Grid, TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { useTranslation } from 'react-i18next'
import { clearNotification, setNotification } from '../../../store/reducers/notifications/reducers'
import { useAppDispatch } from '../../../store'
import { NotificationType } from '../../../store/reducers/notifications/types'
import validoLogo from '../../../assets/svg/valido-logo-login.svg'
import validoByline from '../../../assets/images/login-screen-byline.png'
import { useSelector } from 'react-redux'
import { isCompletedLogin } from '../../../store/reducers/auth/types'
import { useNavigate } from 'react-router'
import Loading from '../../../components/ui/Loading'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  containedButton: {
    fontFamily: 'Nunito Sans',
    fontWeight: 'bold!important',
    textTransform: 'none',
    fontSize: '0.875rem!important',
    borderRadius: '50px',
    borderWidth: '2px',
    letterSpacing: '0',
    color: '#ffffff!important',
    height: '35px',
    borderColor: '#147AC6!important',
    backgroundColor: '#147AC6!important',
    paddingLeft: '30px!important',
    paddingRight: '30px!important',
    marginLeft: '10px!important',
  },
  outlinedButton: {
    fontFamily: 'Nunito Sans',
    fontWeight: 'bold',
    textTransform: 'none',
    fontSize: '0.875rem!important',
    borderRadius: '50px',
    borderWidth: '2px',
    letterSpacing: '0',
    color: '#147AC6!important',
    height: '55px',
    width: '130px',
    borderColor: '#147AC6!important',
  },
  loginModal: {
    background: 'rgba(255,255,255,0.10)',
    padding: '65px 0 80px 0',
    borderRadius: '12px',
    border: 'solid 2px rgba(141,207,255,0.7)',
    width: '740px',
    textAlign: 'left',
    color: '#ffffff',
    backdropFilter: 'blur(10px)',
  },
  gridNoTopMargin: {
    paddingBottom: '0 !important',
  },
  gridNoMargin: {
    paddingTop: '0 !important',
    paddingBottom: '0 !important',
  },
}))

interface IProps {}

const Login: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  let loginCompleted = useSelector(isCompletedLogin)
  const [email, setEmail] = useState<string | null>(null)
  const [password, setPassword] = useState<string | null>(null)
  const [emailError, setEmailError] = useState<string | null>(null)
  const [passwordError, setPasswordError] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  const { t } = useTranslation()

  useEffect(() => {
    if (loginCompleted) {
      setLoading(true)
      setTimeout(() => {
        setLoading(false)
        navigate('/home')
      }, 3000)
    }
  }, [loginCompleted, navigate])

  const signInWithEmailAndPasswordHandler = (event: any) => {
    event.preventDefault()
    dispatch(clearNotification())
    setLoading(true)

    if (!email || !password) {
      if (!email) {
        setEmailError('Email Address Required')
      }
      if (!password) {
        setPasswordError('Password Required')
      }
      return
    }
    const auth = getAuth()
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        setTimeout(() => {
          setLoading(false)
          navigate('/home')
        }, 500)
      })
      .catch((error) => {
        dispatch(
          setNotification({ type: NotificationType.ERROR, messages: ['Username or Password wrong, Please try again'] }),
        )
        setLoading(false)
      })
  }

  const onChangeHandler = (event: any) => {
    const { name, value } = event.currentTarget
    if (name === 'email') {
      setEmail(value)
    } else if (name === 'password') {
      setPassword(value)
    }
  }

  return (
    <>
      {loading && <Loading />}
      <Box className={classes.loginModal}>
        <Container maxWidth="lg" disableGutters={true} className="login-modal-styles">
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
              <img className="valido-login-logo" src={validoLogo} alt="Valido" />
              <img className="valido-login-byline" src={validoByline} alt="The Digital Parking Experience" />
            </Grid>
            <div className="modal-inner-wrapper">
              <Grid item xs={12}>
                <TextField
                  inputProps={{ className: 'validoLoginInput' }}
                  id="email"
                  name="email"
                  placeholder="Username / Email Address"
                  InputLabelProps={{ className: 'validoLoginLabel' }}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  error={emailError ? true : false}
                  onChange={onChangeHandler}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  inputProps={{ className: 'validoLoginInput' }}
                  id="password"
                  name="password"
                  placeholder="Password"
                  type={'password'}
                  InputLabelProps={{ className: 'validoLoginLabel' }}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  error={passwordError ? true : false}
                  onChange={onChangeHandler}
                />
              </Grid>
              <Grid item xs={12}>
                <FormGroup style={{ float: 'left' }}>
                  <FormControlLabel
                    className="login-checkbox-label"
                    control={<Checkbox defaultChecked />}
                    label="Remember me!"
                  />
                </FormGroup>

                <a className="password-link" href="/forgot-password">
                  Forgot password?
                </a>
              </Grid>
              <Grid item xs={12} style={{ paddingTop: '20px', textAlign: 'right' }}>
                <Button
                  className={classes.containedButton}
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={signInWithEmailAndPasswordHandler}
                  disabled={email === null || password === null}
                >
                  {t('login:buttons.login')}
                </Button>
              </Grid>
            </div>
          </Grid>
        </Container>
      </Box>
    </>
  )
}
export default Login
