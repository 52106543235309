import { Station } from '../../../pages/pos/types'
import { PartnerStaff } from '../partner-staffs/types'
import { Campaign } from '../../../pages/campaigns/types'

export interface RewardFlow {
  securityType: SecurityType | null
  selectedStation: Station | null
  signedOnUser: PartnerStaff | null
}

export enum SecurityType {
  WITH_PIN = 'WITH_PIN',
  NO_PIN = 'NO_PIN',
}

export interface RewardFlowState {
  rewardFlow: RewardFlow
}

export const initialFlow: RewardFlow = {
  securityType: null,
  selectedStation: null,
  signedOnUser: null,
}

export interface RewardTransactionDocketEvent {
  id: string
  code: string
  claimId: string
  stationId: string
  partnerId: string
  status: RewardTransactionDocketStatus
  campaign: Campaign
}

export enum RewardTransactionDocketStatus {
  Pending = 'Pending',
  Cancelled = 'Cancelled',
  Completed = 'Completed',
}

export interface RewardTransactionRequest {
  docketId: string
  timeValueMinutes: number
  issuedByStaffId: string | null
  issuedByStaffAuth: string
}

export const PARTNER_DOCKET_API_URL =
  '/reward-service/partners/:partnerId/stations/:stationId/reward-transaction-dockets/stream-sse'

export const PARTNER_TRANSACTION_API_URL = '/reward-service/partners/:partnerId/stations/:stationId/reward-transactions'
