import axios from 'axios'
import { PARTNER_STATIONS_API_URL } from './types'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const fetchStations = createAsyncThunk(
  'station/fetchStations',
  async (partnerId: string, { getState, requestId }) => {
    // @ts-ignore
    const { currentRequestId, loading } = getState().stationsReducer
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }
    const asyncResult = await axios.get('/reward-service' + PARTNER_STATIONS_API_URL.replace(':partnerId', partnerId))
    return asyncResult.data
  },
)

export const fetchStationById = createAsyncThunk(
  'station/fetchStationById',
  async ({ partnerId, stationId }: { partnerId: string; stationId: string }, { getState, requestId }) => {
    // @ts-ignore
    const { currentRequestId, loading } = getState().stationsReducer
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }
    const asyncResult = await axios.get(
      '/reward-service' + PARTNER_STATIONS_API_URL.replace(':partnerId', partnerId) + '/' + stationId,
    )
    return asyncResult.data
  },
)
