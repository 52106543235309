import { IdName, Lifecycle } from '../../store/reducers/common/types'
import { TableColumn } from '../../components/table/types'

export interface PartnerUser {
  uuid: string
  code: string
  clientRef: string
  firstName: string
  lastName: string
  email: string
  userRole: PartnerUserRole
  phone: string
  lifecycle: Lifecycle
  staffClass: StaffClass
  contractEndDate: string
  partnerId: string
  version: number
  new: boolean
  id: string
}

export enum StaffClass {
  Permanent = 'Permanent',
  Contract = 'Contract',
}

export enum PartnerUserRole {
  Admin = 'Admin',
  Staff = 'Staff',
  Supervisor = 'Supervisor',
  Cashier = 'Cashier',
}

export const staffClasses: IdName[] = [
  {
    name: 'Permanent',
    id: 'Permanent',
  },
  {
    name: 'Contract',
    id: 'Contract',
  },
]

export const partnerUserRoles: IdName[] = [
  {
    name: 'Admin',
    id: 'Admin',
  },
  {
    name: 'Staff',
    id: 'Staff',
  },
  {
    name: 'Supervisor',
    id: 'Supervisor',
  },
  {
    name: 'Cashier',
    id: 'Cashier',
  },
]

export interface UserState {
  success: boolean
  error: string | null
  users: PartnerUser[] | null
  user: PartnerUser | null
  currentRequestId: string | undefined
  loading: 'idle' | 'pending'
}

export const PARTNER_USERS_API_URL = '/partners/:partnerId/users'

export const userListColumn: TableColumn[] = [
  {
    id: 'name',
    label: 'First Name',
    name: 'Name',
    sortable: true,
    filterable: false,
    minWidth: 200 / 12,
  },
  {
    id: 'name',
    label: 'Last Name',
    name: 'Name',
    sortable: true,
    filterable: false,
    minWidth: 200 / 12,
  },
  {
    id: 'email',
    label: 'Email',
    name: 'email',
    sortable: true,
    filterable: false,
    minWidth: 400 / 12,
  },
  {
    id: 'Role',
    label: 'Role',
    name: 'role',
    sortable: true,
    filterable: false,
    minWidth: 200 / 12,
  },
  {
    id: 'status',
    label: 'Status',
    name: 'lifecycle',
    sortable: true,
    filterable: false,
    minWidth: 200 / 12,
  },
]
