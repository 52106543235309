export enum Lifecycle {
  Draft = 'Draft',
  Active = 'Active',
  Suspended = 'Suspended',
  Deleted = 'Deleted',
}

export enum InvitationStatus {
  Applied = 'Applied',
  Invited = 'Invited',
  Active = 'Active',
  Suspended = 'Suspended',
}

export interface IdName {
  id: string
  name: string
}

export interface IdOnly {
  id: string
}

export interface LifecycleOnly {
  lifecycle: Lifecycle
}

export const lifeCycleIdNames: IdName[] = [
  {
    name: 'Draft',
    id: 'Draft',
  },
  {
    name: 'Active',
    id: 'Active',
  },
  {
    name: 'Suspended',
    id: 'Suspended',
  },
]
